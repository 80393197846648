import React, { useState } from "react";
import "./MilitaryBackground.css";
import { ReactComponent as MilitaryIcon } from "../../../assets/images/military.svg";   
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";   


import AddMilitary from "./Add Military/AddMilitary";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";

function MilitaryBackground({ candidate, hideEditIcon }) {
  // Modal ko handle karne ke liye state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate); // Track updated candidate data

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate({
      ...updatedCandidate,
      branch: updatedData.branch || "",
      rank: updatedData.rank || "",
      mos: updatedData.mos || "",
      yearsServed: updatedData.yearsServed || "",
    });
  };

  // Toggle visibility of UploadResume component
  const toggleMilitaryBackground = () => {
    setIsModalOpen(!isModalOpen);
  };
  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="target_industries">
      <h4>Military Background</h4>

      {!isModalOpen ? (
        <div>
          {!updatedCandidate.mos ||
          (!candidate.mos && !updatedCandidate.rank) ||
          (!candidate.rank && !updatedCandidate.yearsServed) ||
          (!candidate.yearsServed && !updatedCandidate.branch) ||
          !candidate.branch ? (
            // Show the empty-state if all values are empty
            <div className="empty-state">
              <div className="plusIcon" onClick={toggleMilitaryBackground}>
              <PlusIcon/>
              </div>
              <MilitaryIcon/>
              <p>
                Your military service is valuable! Fill in your branch, rank,
                and MOS to help employers understand your background.
              </p>
              <button onClick={toggleMilitaryBackground}>
                Add Military Background
              </button>
            </div>
          ) : (
            // Show the exist-state if any value exists
            <div className="exist-state">
              {!hideEditIcon && (
                <div className="editIcon" onClick={toggleMilitaryBackground}>
                
                  <EditIcon/>
                </div>
              )}
              <div className="military-values">
                <div className="branch-value">
                  <p>Military Branch</p>
                  <span>
                    <b>{updatedCandidate.branch || candidate.branch}</b>
                  </span>
                </div>

                <div className="branch-value">
                  <p>Rank</p>
                  <span>
                    <b>{updatedCandidate.rank || candidate.rank}</b>
                  </span>
                </div>

                <div className="branch-value">
                  <p>MOS</p>
                  <span>
                    <b>{updatedCandidate.mos || candidate.mos}</b>
                  </span>
                </div>

                <div className="branch-value">
                  <p>Years Served</p>
                  <span>
                    <b>
                      {updatedCandidate.yearsServed || candidate.yearsServed}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <AddMilitary
          isOpen={toggleMilitaryBackground}
          onClose={closeModal}
          candidate={updatedCandidate} // Pass updated candidate to child
          onUpdate={handleUpdate} // Callback for handling updates
        />
      )}
    </div>
  );
}

export default MilitaryBackground;
