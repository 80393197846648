import React, { useState } from "react";
// import profileWarningIcon from "../../../assets/images/profileWarning.png";
import "./ProfileWarning.css";
import { Link } from "react-router-dom";

function ProfileWarning({ candidate }) {
  const [isVisible, setIsVisible] = useState(true); // Visibility ka state

  const hideBox = () => {
    setIsVisible(false); // "X" Hide box Click on X butotn
  };

  // Check condition according to candidate DATA.
  const hasBasicInfo =
    candidate.fname &&
    candidate.lname &&
    candidate.phone &&
    candidate.city &&
    candidate.state;

  const hasMilitaryBackground =
    candidate.branch &&
    candidate.rank &&
    candidate.mos &&
    candidate.yearsServed;

  const hasCompleteProfileInfo =
    candidate.description &&
    candidate.securityClearance &&
    candidate.yearsOfExperience &&
    candidate.idealJobTitle &&
    candidate.relocationprefrence;

  const hasProfilePhoto = candidate.headshotLink;

  // Check karein agar sab kuch theek hai
  const isProfileComplete =
    hasBasicInfo &&
    hasMilitaryBackground &&
    hasProfilePhoto &&
    hasCompleteProfileInfo;

  //if profile is complete so box will be hide
  if (isProfileComplete) {
    return null;
  }

  return (
    isVisible && (
      <div className="profileWarning-box">
        <div
          className="hide-box"
          onClick={hideBox}
          style={{ cursor: "pointer" }}
        >
          X
        </div>
        <div className="profile-description">
          <div className="profileIcon">
            {/* <img src={profileWarningIcon} alt="warningIcon" /> */}
          </div>
          <div>
            <h4>Warning: Your Profile Is Not Public Yet</h4>
            <p>
              Your profile is missing important details. To make it visible to
              hiring companies, please complete the following sections:
            </p>
            <ul>
              {!hasBasicInfo && <li>Basic Information</li>}
              {!hasProfilePhoto && <li>Profile Photo</li>}
              {!hasMilitaryBackground && <li>Military Background</li>}
              {!hasCompleteProfileInfo && <li>About Me</li>}
            </ul>
          </div>
        </div>

        <div className="profile-btn">
          <Link
            className="complete-profile-btn"
            to="/candidate/complete-profile"
          >
            Complete Profile
          </Link>
        </div>
      </div>
    )
  );
}

export default ProfileWarning;
