import React from "react";
import "./CourseBanner.css";
// import courseImg from "../../assets/images/thumbnail_course.png";
function CourseBanner({ course }) {
  return (
    <div className="banner_course">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-8">
          <div className="content">
            <h4>{course.courseTitle} </h4>
            <p>{course.courseDescription}</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-4">
          {/* <img
            src={courseImg}
            alt="course-thumbnail"
            className="card-img-top"
          /> */}
        </div>
      </div>
    </div>
  );
}

export default CourseBanner;
