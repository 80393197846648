import React, { useState } from "react";
import "./UploadResume.css";
import { ReactComponent as UploadIcon } from "../../../../assets/images/uploadIcon.svg";

import fileImg from "../../../../assets/images/file.png";
import { toast, ToastContainer } from "react-toastify";

const UploadResume = ({ candidate, onClose, onUpdate }) => {
  const [resume, setResume] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const validateFile = (file) => {
    if (file && allowedFileTypes.includes(file.type)) {
      setError("");
      setResume(file);
    } else {
      setError(
        "Invalid file type! Only .pdf, .doc, and .docx files are allowed."
      );
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    validateFile(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    validateFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSaveChanges = () => {
    if (!resume) {
      setError("Please select a resume first.");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("resume", resume);

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${process.env.REACT_APP_API_URL}/api/admin/candidates/uploadOrigionalResume/${candidate._id}`
    );

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        setProgress(100);

        toast.success("Resume uploaded successfully!");

        if (onUpdate) {
          onUpdate(resume); // Sirf resume ko parent component mein bhejna hai
        }

        setTimeout(() => {
          if (onClose) {
            setUploading(false);
            onClose(); // Form ko close kar dena
          }
        }, 2000);
      } else {
        setError("Error uploading resume. Please try again.");
        setUploading(false);
      }
    };

    xhr.onerror = () => {
      toast.error("Failed to upload resume. Please check your connection.");
      setError("Error uploading resume: Unknown error occurred.");
      setUploading(false);
    };

    xhr.send(formData);
  };

  return (
    <div
      className="upload-resume upload_resume"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <ToastContainer />
      <div className="upload-box">
        {resume ? (
          <div className="resume-preview">
            {resume.type === "application/pdf" ? (
              <iframe
                src={URL.createObjectURL(resume)}
                style={{ width: "100%", height: "400px" }}
                title="Resume Preview"
              ></iframe>
            ) : (
              <div className="file-placeholder">
                <img src={fileImg} alt="file-icon" />
                <p>{resume.name}</p>
              </div>
            )}
          </div>
        ) : (
          <>
       <UploadIcon/>
            <p className="upload-instructions">
              Drag & drop files or{" "}
              <label htmlFor="file-upload" className="browse-link">
                <b>Browse</b>
              </label>
            </p>
            <p className="supported-formats">
              Supported formats: .pdf, .doc, .docx
            </p>
          </>
        )}
        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>

      {uploading && (
        <div className="progress-bar">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <div>
              <img src={fileImg} alt="fileImg" style={{ width: "30px" }} />
            </div>

            <div>
              <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                Uploading...
              </p>

              <b style={{ fontSize: "12px" }}>{resume.name}</b>
            </div>
          </div>
          <div
            style={{
              margin: "0px",
            }}
          >
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}

      <button
        onClick={handleSaveChanges}
        className="btn-save-changes"
        disabled={uploading}
      >
        Save
      </button>
    </div>
  );
};

export default UploadResume;
