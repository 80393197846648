import React, { useState } from "react";
import "./UploadFiles.css";
import { ReactComponent as UploadIcon } from "../../../assets/images/uploadIcon.svg";
import fileImg from "../../../assets/images/file.png";
import { toast, ToastContainer } from "react-toastify";

const UploadFiles = ({ candidate, onClose }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [fileType, setFileType] = useState(""); // Track file type selection
  const [showFileTypePrompt, setShowFileTypePrompt] = useState(false); // Show file type prompt

  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const validateFile = (file) => {
    if (file && allowedFileTypes.includes(file.type)) {
      setError("");
      setFile(file);
      setShowFileTypePrompt(true); // Show prompt once a file is selected
    } else {
      setError(
        "Invalid file type! Only .pdf, .doc, and .docx files are allowed."
      );
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    validateFile(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    validateFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileTypeSelection = (type) => {
    setFileType(type);
    setShowFileTypePrompt(false); // Hide the prompt after selection
  };

  const handleSave = () => {
    if (!fileType) {
      setError("Please select a file type before saving.");
      return;
    }

    const formData = new FormData();
    const fieldName = fileType === "Resume" ? "resume" : "file"; // Set field name dynamically
    formData.append(fieldName, file); // Append file to FormData with the dynamic field name

    setUploading(true);
    const apiEndpoint =
      fileType === "Resume"
        ? `${process.env.REACT_APP_API_URL}/api/admin/candidates/uploadOrigionalResume/${candidate._id}`
        : `${process.env.REACT_APP_API_URL}/api/admin/candidates/uploadFiles/${candidate._id}`;

    const xhr = new XMLHttpRequest();
    xhr.open("POST", apiEndpoint);

    xhr.onload = () => {
      if (xhr.status === 200) {
        setUploading(false);
        toast.success("File uploaded successfully!");
        setFile(null); // Clear file after success
      } else {
        setError("Error uploading file. Please try again.");
        setUploading(false);
      }
    };

    xhr.onerror = () => {
      toast.error("Failed to upload file. Please check your connection.");
      setError("Error uploading file: Unknown error occurred.");
      setUploading(false);
    };

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100;
        setProgress(percentComplete);
      }
    };

    xhr.send(formData);
  };

  return (
    <div
      className="upload-files"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <ToastContainer />
      <div className="upload-box" style={{ position: "relative" }}>
        {/* File type selection prompt */}
        {showFileTypePrompt && (
          <div className="file-type-prompt">
            <p>Please select the file type:</p>
            <button onClick={() => handleFileTypeSelection("Resume")}>
              Resume
            </button>
            <button onClick={() => handleFileTypeSelection("Other")}>
              Other File
            </button>
          </div>
        )}

        {/* File preview section */}
        {!showFileTypePrompt && file && (
          <div className="file-preview">
            {file.type === "application/pdf" ? (
              <>
                <button
                  onClick={handleSave}
                  className="btn-save-changes"
                  disabled={uploading}
                >
                  Save
                </button>
                <iframe
                  src={URL.createObjectURL(file)}
                  style={{ width: "100%", height: "400px" }}
                  title="File Preview"
                ></iframe>
              </>
            ) : (
              <div className="file-placeholder-uploadfiles">
                <button
                  onClick={handleSave}
                  className="btn-save-changes"
                  disabled={uploading}
                >
                  Save
                </button>
                <img src={fileImg} alt="file-icon" />
                <p>{file.name}</p>
              </div>
            )}
          </div>
        )}

        {/* Drag and drop area */}
        {!showFileTypePrompt && !file && (
          <>

        <UploadIcon/>
            <p className="upload-instructions">
              Drag & drop files or{" "}
              <label htmlFor="file-upload" className="browse-link">
                <b>Browse</b>
              </label>
            </p>
            <p className="supported-formats">
              Supported formats: .pdf, .doc, .docx
            </p>
          </>
        )}
        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>

      {/* Uploading progress */}
      {uploading && (
        <div className="progress-bar">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <div>
              <img src={fileImg} alt="fileImg" style={{ width: "30px" }} />
            </div>

            <div>
              <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                Uploading...
              </p>

              <b style={{ fontSize: "12px" }}>{file.name}</b>
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: "10px !important",
            }}
          >
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default UploadFiles;
