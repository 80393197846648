// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verified_icon{
    width: 20px;
    position: absolute;
    right: 5px;
}

.allAccessPlan{
    background-color: #9345f8  !important;
   color: white;
  }
  
  .basicPlan{
    background-color: #e8e9eb;
  }

  .enterprisePlan{
background-color: #B58B03;
color: white;
}

.company-list-table tr th{
min-width: 273px;
}`, "",{"version":3,"sources":["webpack://./src/components/Companies/Company List/CompanyListTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,qCAAqC;GACtC,YAAY;EACb;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;AACF,yBAAyB;AACzB,YAAY;AACZ;;AAEA;AACA,gBAAgB;AAChB","sourcesContent":[".verified_icon{\n    width: 20px;\n    position: absolute;\n    right: 5px;\n}\n\n.allAccessPlan{\n    background-color: #9345f8  !important;\n   color: white;\n  }\n  \n  .basicPlan{\n    background-color: #e8e9eb;\n  }\n\n  .enterprisePlan{\nbackground-color: #B58B03;\ncolor: white;\n}\n\n.company-list-table tr th{\nmin-width: 273px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
