import React, { useState } from "react";
import "./AboutCompany.css";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";

import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";   // Assuming you have a close icon
import EditAboutCompany from "./EditAboutCompany";



function AboutCompany() {
  const [isEditing, setIsEditing] = useState(false); // State to toggle between edit and view modes

  const toggleEdit = () => {
    setIsEditing(!isEditing); // Toggle the editing state
  };

  return (
    <div className="about_company_sec">
      <div className="about_heading">
        <h4>About Company</h4>

        {/* Toggle edit icon to close icon when editing */}
        <EditIcon  onClick={toggleEdit}/>
        
 
      </div>

      {/* Conditionally render sections based on editing state */}
      {!isEditing ? (
        <div className="about_details">
          <div className="about_detail">
            <p>
              <b>Website URL</b>
            </p>
            <p>https://www.humanit.com</p>
          </div>
          <div className="about_detail">
            <p>
              <b>Industry</b>
            </p>
            <p>
              Information Technology - Cyber Security, Software Development &
              Engineering, Banking, Finance & Insurance, Manufacturing,
              Construction, Oil & Gas, Automotive
            </p>
          </div>
          <div className="about_detail">
            <p>
              <b>Company Description</b>
            </p>
            <p>
              CyberSurance is a leader in providing cyber-security consulting
              services with a focus on providing resources and expertise to help
              business organizations navigate the complexities of developing and
              operating a security management program.
            </p>
          </div>
        </div>
      ) : (
        <EditAboutCompany />
      )}
    </div>
  );
}

export default AboutCompany;
