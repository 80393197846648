// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resume_list{
    background-color: white;
    display: flex
;
    justify-content: space-between;
    align-items: center;
    padding: 13px 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px #808080a8;
}

.resume_list p{
    margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/Candidates/ResumeList/ResumeList.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB;AACJ;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".resume_list{\n    background-color: white;\n    display: flex\n;\n    justify-content: space-between;\n    align-items: center;\n    padding: 13px 15px;\n    margin-bottom: 10px;\n    border-radius: 10px;\n    box-shadow: 0px 1px 3px 0px #808080a8;\n}\n\n.resume_list p{\n    margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
