import React, { useState, useEffect } from "react";
import "./CourseChapterList.css";
// import yesIcon from "../../assets/images/yesIcon.png";
// import downImg from "../../assets/images/down-arrow.png";

const CourseChapterList = ({ chapters, course }) => {
  const [expandedChapters, setExpandedChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(null);
  const [activeTab, setActiveTab] = useState("content");

  // Function to toggle chapter expansion/collapse
  const handleChapterToggle = (chapterNumber) => {
    if (expandedChapters.includes(chapterNumber)) {
      setExpandedChapters(
        expandedChapters.filter((num) => num !== chapterNumber)
      );
    } else {
      setExpandedChapters([...expandedChapters, chapterNumber]);
    }
  };

  // Function to handle module selection
  const handleModuleClick = (chapter, moduleIndex) => {
    setSelectedChapter(chapter);
    setSelectedModuleIndex(moduleIndex);
    setActiveTab("content");
  };

  const markModuleAsCompleted = async (chapterNumber, moduleNumber) => {
    try {
      const response = await fetch(
        `http://localhost:5000/api/courses/update/${course._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ chapterNumber, moduleNumber, course }), // Include both numbers in the body
        }
      );

      if (!response.ok) {
        const errorText = await response.text(); // Capture the error response
        console.error("Failed to mark module as completed:", errorText);
        return;
      }

      const data = await response.json();
      console.log("Module marked as completed:", data);
      // Optionally update the UI or state
    } catch (error) {
      console.error("Error updating module:", error);
    }
  };

  // Function to handle "Next" button click
  const handleNextModule = async () => {
    if (selectedModuleIndex !== null && selectedChapter) {
      const currentModule = selectedChapter.modules[selectedModuleIndex];

      // Mark current module as completed by passing chapterNumber and moduleNumber
      await markModuleAsCompleted(
        selectedChapter.chapterNumber,
        currentModule.moduleNumber
      );

      // Go to the next module
      const nextModuleIndex = selectedModuleIndex + 1;
      if (nextModuleIndex < selectedChapter.modules.length) {
        setSelectedModuleIndex(nextModuleIndex);
      } else {
        // Move to the next chapter if the current one is completed
        const nextChapterIndex =
          chapters.findIndex(
            (ch) => ch.chapterNumber === selectedChapter.chapterNumber
          ) + 1;
        if (nextChapterIndex < chapters.length) {
          setSelectedChapter(chapters[nextChapterIndex]);
          setSelectedModuleIndex(0);
        }
      }
    }
  };

  useEffect(() => {
    if (chapters.length > 0) {
      setSelectedChapter(chapters[0]);
      setSelectedModuleIndex(0);
      setExpandedChapters([chapters[0].chapterNumber]);
    }
  }, [chapters]);

  return (
    <div className="course_sections">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-12">
          <div className="left-chapter-list">
            <span>Course Progression</span>
            <div className="custom-progress detail-progress">
              <div
                className="custom-progress-bar"
                style={{ width: `${course.courseProgress}%` }}
              >
                {course.courseProgress}%
              </div>
            </div>
            <ul className="chapter-heading">
              {chapters.map((chapter) => (
                <li key={chapter.chapterNumber}>
                  <div
                    className="chapter-list-arrow"
                    onClick={() => handleChapterToggle(chapter.chapterNumber)}
                  >
                    <div className="chapter-list">{chapter.chapterTitle}</div>

                    <div>
                      {/* <img src={downImg} className="down_img" /> */}
                    </div>
                  </div>

                  {expandedChapters.includes(chapter.chapterNumber) && (
                    // <ul className="module_ul">
                    //   {chapter.modules.map((module, index) => (
                    //     <li
                    //       key={module.moduleNumber}
                    //       onClick={() => handleModuleClick(chapter, index)}
                    //     >
                    //       {module.moduleTitle}

                    //       {module.isCompleted === true && (
                    //         <img src={yesIcon} alt="tracking-icon" />
                    //       )}
                    //     </li>
                    //   ))}
                    // </ul>

                    <ul className="module_ul">
                      {chapter.modules.map((module, index) => (
                        <li
                          key={module.moduleNumber}
                          onClick={() => handleModuleClick(chapter, index)}
                          className={
                            selectedModuleIndex === index &&
                            selectedChapter === chapter
                              ? "active"
                              : ""
                          }
                        >
                          {module.moduleTitle}
                          {module.isCompleted === true && (
                            // <img src={yesIcon} alt="tracking-icon" />
                            <></>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="col-lg-9 col-md-9 col-12">
          {selectedChapter && selectedModuleIndex !== null && (
            <div className="course_module_section">
              <div className="module-header">
                <h4 className="module-title">
                  {selectedChapter.modules[selectedModuleIndex].moduleTitle}
                </h4>
                {selectedChapter.modules[selectedModuleIndex].isCompleted ===
                  true &&
                  //  <img src={yesIcon} alt="tracking-icon" />
                  <></>
                  }
              </div>

              <hr />

              <div className="module-content">
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: 0,
                    overflow: "hidden",

                    borderRadius: "10px",
                    border: "1px solid #cec8c875",
                    marginBottom: "20px",
                  }}
                >
                  <iframe
                    src="https://www.youtube.com/embed/kUy-F0ab3hM"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </div>

                <div className="tab-navigation">
                  <button
                    className={activeTab === "content" ? "active-tab" : ""}
                    onClick={() => setActiveTab("content")}
                  >
                    Content
                  </button>
                  <button
                    className={
                      activeTab === "transcript"
                        ? "active-tab transcriptbtn"
                        : "transcriptbtn"
                    }
                    onClick={() => setActiveTab("transcript")}
                  >
                    Transcript
                  </button>
                </div>

                <div className="tab-content">
                  {activeTab === "content" && (
                    <div className="content-tab">
                      <p>
                        {
                          selectedChapter.modules[selectedModuleIndex]
                            .moduleContent
                        }
                      </p>
                    </div>
                  )}
                  {activeTab === "transcript" && (
                    <div className="transcript-tab">
                      <p>
                        {
                          selectedChapter.modules[selectedModuleIndex]
                            .moduleTranscript
                        }
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="module_footer">
                <button className="next_module" onClick={handleNextModule}>
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseChapterList;
