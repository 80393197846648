import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import CandidateTable from "../../components/Candidates/Candidate Records Table/CandidateTable";
import CandidateSearch from "../../components/Candidates/Search Filters/CandidateSearch";
import ColumnEditModal from "../../components/Candidates/Candidate Records Table/ColumnEditModal";
import Header from "../../components/Common/Header/Header";
import Sidebar from "../../components/Common/Sidebar/Sidebar";
import "../AdminDashboard.css";
import { ReactComponent as FilterIcon } from "../../assets/images/FilterIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/EditIcon.svg";

import AddFilterModal from "../../components/Candidates/Add Filters/AddNewFilter";
import ManagerFilter from "../../components/Candidates/Add Filters/ManagerFilter";
import TextSearchFilter from "../../components/Candidates/Add Filters/TextSearchFilter";
import CandidateTypeFilter from "../../components/Candidates/Add Filters/CandidateTypeFilter";
import MilitaryBranchFilter from "../../components/Candidates/Add Filters/MilitaryBranchFilter";
import JobSearchStatusFilter from "../../components/Candidates/Add Filters/JobSearchStatusFilter";
import RelocationFilter from "../../components/Candidates/Add Filters/RelocationFilter";
import SecurityClearanceFilter from "../../components/Candidates/Add Filters/SecurityClearanceFilter";
import TokenDecode from "../../context/DecodeJWT";
import AddCandidateForm from "../../components/Candidates/Add New Candidates/AddNewCandidateForm";

const AdminCandidateDashboard = () => {
  const decodedToken = TokenDecode();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [candidateData, setCandidateData] = useState([]); // Initialize candidate data as empty array
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);
  const [isTextSearchModalOpen, setIsTextSearchModalOpen] = useState(false);
  const [isRelocationModalOpen, setIsRelocationModalOpen] = useState(false);
  const [isCertificationModalOpen, setIsCertificationModalOpen] =
    useState(false);

  const [isSecurityClearanceModalOpen, setIsSecurityClearanceModalOpen] =
    useState(false);

  const [isJobStatusModalOpen, setIsJobStatusModalOpen] = useState(false);

  const [isCandidateTypeModalOpen, setIsCandidateTypeModalOpen] =
    useState(false);
  const [isMilitaryBranchModalOpen, setMilitaryBranchModalOpen] =
    useState(false);

  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedCandidateType, setSelectedCandidateType] = useState(null);
  const [selectedMilitaryBranch, setSelectedMilitaryBranch] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState(null);
  const [selectedRelocation, setSelectedRelocation] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(null);

  const [selectedSecurityClearance, setSelectedSecurityClearance] =
    useState(null);

  const [showAddCandidateForm, setShowAddCandidateForm] = useState(false);

  const toggleAddNewForm = () => {
    setShowAddCandidateForm(!showAddCandidateForm); // Toggle the visibility of the form
  };
  const handleSelectedCertification = (selectedCertifications) => {
    setSelectedCertification(selectedCertifications); // Save the selected certifications

    const filtered = candidateData.filter((candidate) => {
      // Ensure candidate.certifications is an array and check if any of the selected certifications are in the candidate's certifications array
      return selectedCertifications.every((selectedCert) =>
        candidate.certifications?.some(
          (cert) =>
            cert &&
            String(cert).toLowerCase() === String(selectedCert).toLowerCase()
        )
      );
    });

    setFilteredData(filtered); // Update filtered data based on selected certifications
  };



  const handleSecurityClearance = (securityClearance) => {
    console.log("Selected security clearance:", securityClearance); // Debugging line

    // If the securityClearance is an object, extract the string value
    const securityClearanceString =
      typeof securityClearance === "object" &&
      securityClearance.securityClearance
        ? securityClearance.securityClearance
        : securityClearance;

    setSelectedSecurityClearance(securityClearanceString);

    // Ensure securityClearance is a string, fallback to empty string if it's not
    const securityClearanceName =
      typeof securityClearanceString === "string"
        ? securityClearanceString.trim().toLowerCase()
        : "";

    // Filter candidates based on security clearance (both trimmed and lowercased)
    const filtered = candidateData.filter((candidate) => {
      const candidateSecurityClearance =
        typeof candidate.securityClearance === "string"
          ? candidate.securityClearance.trim().toLowerCase()
          : ""; // Fallback to empty string if not a string
      return candidateSecurityClearance === securityClearanceName;
    });

    // Log the filtered data for debugging
    console.log("Filtered data:", filtered);

    // Update the filteredData state
    setFilteredData(filtered);
  };

  const handleSelectRelocation = (relocation) => {
    console.log("Selected relocation:", relocation); // Debugging line

    // If the relocation is an object, extract the string value
    const relocationString =
      typeof relocation === "object" && relocation.relocation
        ? relocation.relocation
        : relocation;

    setSelectedRelocation(relocationString);

    // Ensure relocation is a string, fallback to empty string if it's not
    const relocationName =
      typeof relocationString === "string"
        ? relocationString.trim().toLowerCase()
        : "";

    // Filter candidates based on relocation preference (both trimmed and lowercased)
    const filtered = candidateData.filter((candidate) => {
      const candidateRelocation =
        typeof candidate.relocationprefrence === "string"
          ? candidate.relocationprefrence.trim().toLowerCase()
          : ""; // Fallback to empty string if not a string
      return candidateRelocation === relocationName;
    });

    // Log the filtered data for debugging
    console.log("Filtered data:", filtered);

    // Update the filteredData state
    setFilteredData(filtered);
  };

  const handleSelectStatus = (jobstatus) => {
    setSelectedJobStatus(jobstatus);

    console.log("Filtering candidates with job status:", jobstatus);

    // If jobstatus is an object, extract the correct string property (e.g., 'name' or 'statusName')
    const jobstatusName =
      jobstatus && typeof jobstatus === "object"
        ? jobstatus.statusName
        : jobstatus;

    const filtered = candidateData.filter((candidate) => {
      const statusName = candidate.jobSearchStatusName || "";
      console.log(
        `Candidate job status: ${statusName}, Filter status: ${jobstatusName}`
      );
      return statusName === jobstatusName;
    });

    setFilteredData(filtered);
  };

  const handleSelectBranch = (branch) => {
    setSelectedMilitaryBranch(branch);
    const filtered = candidateData.filter(
      (candidate) => candidate.branch && candidate.branch === branch
    );
    setFilteredData(filtered);
  };

  const handleManagerSelect = (managerName) => {
    setSelectedManager(managerName);
    const filtered = candidateData.filter(
      (candidate) => candidate.name && candidate.name === managerName
    );
    setFilteredData(filtered);
  };

  const handleCandidateTypeSelect = (candidateType) => {
    setSelectedCandidateType(candidateType);
    const filtered = candidateData.filter(
      (candidate) => candidate.type && candidate.type === candidateType
    );
    setFilteredData(filtered);
  };

  const resetTableData = () => {
    setFilteredData(candidateData); // Reset to original candidate data
  };
  const handleSelectCandidate = (selectedCandidate) => {
    setSelectedCandidate(selectedCandidate);

    // Assuming selectedCandidate is an object and you're comparing a specific property, like name
    const filtered = candidateData.filter((candidate) =>
      Object.values(candidate).some(
        (value) =>
          value &&
          String(value).toLowerCase() ===
            String(selectedCandidate.name).toLowerCase() // Use the specific field, like name
      )
    );

    setFilteredData(filtered);
  };

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  const [columns, setColumns] = useState([
    { id: 1, name: "Name", field: "fname", visible: true },
    { id: 4, name: "Candidate Type", field: "candidateType", visible: true }, // `type` field mapped here
    { id: 3, name: "Manager", field: "manager", visible: true },
    { id: 10, name: "Job & Stage", field: "jobs", visible: true },
    {
      id: 3,
      name: "Profile Progress",
      field: "profileProgress",
      visible: true,
    },

    { id: 3, name: "Location", field: "city", visible: true },
    { id: 2, name: "Last activity", field: "lastActivity", visible: true },
    { id: 5, name: "Phone", field: "phone", visible: true },
    { id: 6, name: "Email", field: "email", visible: true },
    { id: 7, name: "Date Added", field: "dateAdded", visible: true },

    { id: 9, name: "Affiliate", field: "affiliate", visible: true },

    { id: 11, name: "Company & Stage", field: "companyStage", visible: true },
    { id: 12, name: "Hot Candidate", field: "hotCandidate", visible: true },

  ]);

  const handleFilterClick = (filter) => {
    if (filter === "Manager") {
      setIsManagerModalOpen(true);
    }

    if (filter === "Text Search") {
      setIsTextSearchModalOpen(true);
    }

    if (filter === "Candidate Type") {
      setIsCandidateTypeModalOpen(true);
    }

    if (filter === "Military Branch") {
      setMilitaryBranchModalOpen(true);
    }

    if (filter === "Job Search Status") {
      setIsJobStatusModalOpen(true);
    }

    if (filter === "Relocation Option") {
      setIsRelocationModalOpen(true);
    }

    if (filter === "Security Clearance") {
      setIsSecurityClearanceModalOpen(true);
    }

    if (filter === "Certifications") {
      setIsCertificationModalOpen(true);
    } else {
      console.log(`${filter} filter clicked!`);
    }
  };
  const toggleColumnVisibility = (id) => {
    setColumns(
      columns.map((col) =>
        col.id === id ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const onColumnReorder = (newColumns) => {
    setColumns(newColumns);
  };

  // Fetch candidate data from API
  useEffect(() => {
    const fetchCandidateData = async () => {
      try {
        const response = await axios.get(`/api/admin/candidates`);
        setCandidateData(response.data); // Set fetched data to candidateData
        setFilteredData(response.data); // Initialize filteredData with the fetched data
        console.log("Candidate Data", response.data);
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    fetchCandidateData();
  }, []);

  // Filter candidate data based on search term
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = candidateData.filter((candidate) =>
      Object.values(candidate).some(
        (value) => String(value).toLowerCase().includes(lowerCaseSearchTerm) // Safely convert to string
      )
    );
    setFilteredData(filtered);
  }, [searchTerm, candidateData]);


  // Fatch Admin Data 
  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        setLoading(true);
        const userEmail = decodedToken?.email;
     
      if (userEmail) {
          const response = await axios.get(`/api/admin/?email=${userEmail}`);
          setAdmin(response.data);
          console.log(response.data.email
          );
        }
      } catch (error) {
        console.log(
          "Error fetching Admin:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAdmin();
  }, [decodedToken]);

  if (loading) {
    return (
      <div className="loading-container">
 <Logo style={{width:"150px"}}/>
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Header admin={admin} />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar onToggleSidebar={handleToggleSidebar} admin={admin} />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            <div className="main_heading_dashboard">
              <div className="left_content">
                <h4 style={{ color: "black" }}>Candidates</h4>
                <span>{filteredData.length} Candidates</span>
              </div>

              <div className="right_content">
                <Link to="/admin/create-new-candidates">
                  <button>Add New Candidate</button>
                </Link>
                {showAddCandidateForm && <AddCandidateForm />}
              </div>
            </div>

            <div className="search_filter_section">
              <div className="row">
                <div className="col-lg-7 col-md-12">
                  <div className="search_bar">
                    <CandidateSearch onSearch={setSearchTerm} />
                  </div>
                </div>

                <div className="col-lg-5 col-md-12">
                  <div className="select_options_filter">
                    <button
                      className="add_new_filter_company"
                      onClick={() => setIsFilterModalOpen(true)}
                    >
                
                   <FilterIcon style={{marginRight:"6px"}}/>
                      Add Filter
                    </button>

                    <button
                      className="edit_filter_company"
                      onClick={() => setIsFilterModalOpen(true)}
                    >
                   
                      <EditIcon style={{marginRight:"6px"}}/>
                      Edit Filters (3)
                    </button>
                    <span style={{ fontSize: "12px", color: "#212529" }}>
                      {" "}
                      Sort By:
                    </span>
                    <select className="recently-added">
                      <option>Recently Added</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="candidate_table_records">
                    <CandidateTable
                      columns={columns}
                      data={filteredData}
                      admin={admin}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-4">
                  <div className="addFilterModal-candidate-page">
                    <AddFilterModal
                      isOpen={isFilterModalOpen}
                      onClose={() => setIsFilterModalOpen(false)}
                      onFilterClick={handleFilterClick}
                    />

                    <ManagerFilter
                      isOpen={isManagerModalOpen}
                      onClose={() => setIsManagerModalOpen(false)}
                      candidateData={candidateData} // Pass candidate data here
                      onSelectManager={handleManagerSelect} // Pass the handler
                      onResetFilters={resetTableData}
                    />

                    <TextSearchFilter
                      isOpen={isTextSearchModalOpen}
                      onClose={() => setIsTextSearchModalOpen(false)}
                      candidateData={candidateData}
                      onSelectCandidate={handleSelectCandidate}
                    />

                    <CandidateTypeFilter
                      isOpen={isCandidateTypeModalOpen}
                      onClose={() => setIsCandidateTypeModalOpen(false)}
                      candidateData={candidateData}
                      onSelectCandidateType={handleCandidateTypeSelect}
                    />

                    <MilitaryBranchFilter
                      isOpen={isMilitaryBranchModalOpen}
                      onClose={() => setMilitaryBranchModalOpen(false)}
                      candidateData={candidateData}
                      onSelectBranch={handleSelectBranch}
                    />

                    <JobSearchStatusFilter
                      isOpen={isJobStatusModalOpen}
                      onClose={() => setIsJobStatusModalOpen(false)}
                      candidateData={candidateData}
                      onSelectStatus={handleSelectStatus}
                    />

                    <RelocationFilter
                      isOpen={isRelocationModalOpen}
                      onClose={() => setIsRelocationModalOpen(false)}
                      candidateData={candidateData}
                      onSelectRelocationPreference={handleSelectRelocation}
                    />

                    <SecurityClearanceFilter
                      isOpen={isSecurityClearanceModalOpen}
                      onClose={() => setIsSecurityClearanceModalOpen(false)}
                      candidateData={candidateData}
                      onSelectSecurityClearance={handleSecurityClearance}
                    />

                </div>
              </div>
            </div>
</div>
            {/* Button to open modal */}

            <ColumnEditModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              columns={columns}
              toggleColumnVisibility={toggleColumnVisibility}
              onColumnReorder={onColumnReorder}
            />
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminCandidateDashboard;
