import React from "react";
import { ReactComponent as CompanyIcon } from "../../../assets/images/companyIcon.svg";

import "./AssignedCompanyBox.css";
function AssignedCompanyBox({ companyData }) {
  return (
    <div className="box_count">
      <div className="box_count_profile">
        <CompanyIcon style={{marginRight:"20px"}}/>
      </div>
      <div className="counting_number">
        <h6>
          {companyData.length} <span>NEW</span>{" "}
        </h6>

        <p>Assigned Companies</p>
      </div>
    </div>
  );
}

export default AssignedCompanyBox;
