import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
// import courseImg from "../../assets/images/thumbnail_course.png";
import "./CourseList.css";
import { Link } from "react-router-dom";
const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 3; // per page 2 records

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await fetch("http://localhost:5000/api/courses");
      const data = await response.json();
      setCourses(data);
    };

    fetchCourses();
  }, []);

  // Calculate the current courses
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Total pages calculation
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(courses.length / coursesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="course-card">
      {currentCourses.length > 0 ? (
        <div className="row">
          {currentCourses.map((course) => (
            <div key={course._id} className="col-lg-4 col-md-6 mb-4">
              <div className="card">
                <Link to={`/candidate/course/${course._id}`}>
                  {" "}
                  {/* Wrap card in Link */}
                  {/* <img
                    src={courseImg}
                    alt="course-thumbnail"
                    className="card-img-top"
                  /> */}
                  <div className="card-body">
                    <h5 className="card-title">{course.courseTitle}</h5>
                    <p className="course-desc-half">
                      {course.courseDescription}
                    </p>
                    {/* Custom Progress Bar */}
                    <div className="custom-progress">
                      <div
                        className="custom-progress-bar"
                        style={{ width: `${course.courseProgress}%` }}
                      >
                        {course.courseProgress}%
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No courses available</p>
      )}
      {pageNumbers.length > 1 && (
        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                <a onClick={() => paginate(number)} className="page-link">
                  {number}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default CourseList;
