import React, { useState } from "react";
import "./Resume.css";
import { ReactComponent as ResumeIcon } from "../../../assets/images/resume.svg";  


import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";     
import UploadResume from "./UploadResume/UploadOriginalResume";
   
import { ReactComponent as PdfIcon } from "../../../assets/images/pdfIcon.svg";  
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";

import ViewResume from "./ViewResume"; // Adjust the path if necessary
import ViewRedactedResume from "./ViewResume";
import UploadRedactedResume from "./UploadResume/UploadOriginalResume";

function OriginalResume({ candidate }) {
  // State to control visibility of UploadResume component
  const [showUploadResume, setShowUploadResume] = useState(false);
  const [isModalResumeOpen, setIsModalResumeOpen] = useState(false);
  const [UpdatedResume, setUpdatedResume] = useState(candidate);

  const handleUpdate = (updatedData) => {
    setUpdatedResume({
      ...UpdatedResume,
      OriginalResume: updatedData, // yahan aapko resume (file) pass karna hai
    });
  };

  // Toggle visibility of UploadResume component
  const toggleUploadResume = () => {
    setShowUploadResume(!showUploadResume);
  };

  const openResumeModal = () => {
    setIsModalResumeOpen(true);
  };

  const closeResumeModal = () => {
    setIsModalResumeOpen(false);
  };

  return (
    <div className="resume-sec">
      <h4>Resume</h4>

      {/* Conditionally render either the Resume view or the UploadResume component */}
      {!showUploadResume ? (
        candidate.resumeUrl ? (
          <div className="show-state">
            <div className="editIcon" onClick={toggleUploadResume}>
                    <EditIcon/>
            </div>
            <div className="resume-link">
              <div>
               <PdfIcon/>
             
                <span style={{ paddingLeft: "5px" }}>
                  {candidate.fname}-Resume...
                </span>
              </div>
              <div>
                <button onClick={openResumeModal}>View Resume</button>
              </div>
            </div>
          </div>
        ) : (
          <div className="empty-state">
            <div className="plusIcon" onClick={toggleUploadResume}>
            <PlusIcon/>
            </div>
         <ResumeIcon/>
            <p>
              A resume is crucial for attracting potential employers. Upload
              your resume to give companies a complete view of your
              qualifications.
            </p>
            <button onClick={toggleUploadResume}>Upload Resume</button>
          </div>
        )
      ) : (
        <UploadResume
          isOpen={showUploadResume}
          onClose={toggleUploadResume}
          onUpdate={handleUpdate}
          candidate={UpdatedResume}
        />
      )}

      {/* ViewResume Modal */}
      <ViewResume
        isOpen={isModalResumeOpen}
        onClose={closeResumeModal}
        candidate={candidate}
        RedactedResume={UpdatedResume.OriginalResume}
      />
    </div>
  );
}

export default OriginalResume;
