import React, { useState, useRef, useContext } from "react";

import "./UpdateBasicInformationPopup.css";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { toast, ToastContainer } from "react-toastify";
import { statesWithCities } from "./LocationList";
import InputMask from "react-input-mask"; // Importing react-input-mask
import CandidateProfileBox from "../../Candidates/Profile/CandidateProfileBox";
// import CandidateProfileBox from "../../Profile/CandidateProfileBox";

function UpdateBasicInformation({ isOpen, onUpdate, onClose, candidate }) {
  const [formData, setFormData] = useState({
    ...candidate,
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    email: candidate.email || "",
    phone: candidate.phone || "",
    idno: candidate.recruiterFlowId || "",
    state: candidate.state || "",
    city: candidate.city || "",
    candidateType: candidate.type,
  });

  const [errors, setErrors] = useState({});
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.fname) newErrors.fname = "This field can not be empty";
    if (!formData.lname) newErrors.lname = "This field can not be empty";

    if (!formData.phone) newErrors.phone = "This field can not be empty";
    if (!formData.idno) newErrors.idno = "This field can not be empty";
    if (!formData.city) newErrors.city = "This field can not be empty";
    if (!formData.state) newErrors.state = "This field can not be empty";
    if (!formData.candidateType)
      newErrors.candidateType = "This field can not be empty";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/admin/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");

      if (onUpdate) {
        onUpdate(formData); // Send updated candidate to the parent component
      }

      setTimeout(() => {
        if (onClose) {
          onClose(); // This will close the form by changing the state in the parent
        }
      }, 2000); // Close the form after the update
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  const inputref = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handleOnPlacesChanged = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the formData state
        setFormData((prevData) => ({
          ...prevData,
          city: cityName,
          state: stateName,
        }));
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="popup-content updateprofileform">
        <h6>Candidate Information</h6>

        <CandidateProfileBox candidate={candidate} />
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className="two-fields">
            <div className="form-field">
              <label>
                First Name <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Type Here"
                name="fname"
                value={formData.fname}
                onChange={handleChange}
                className={!formData.fname ? "error-border" : ""} // Adds class if empty
              />

              {errors.fname && (
                <span className="error-message-field">{errors.fname}</span>
              )}
            </div>

            <div className="form-field">
              <label>
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Type Here"
                name="lname"
                value={formData.lname}
                onChange={handleChange}
                className={!formData.lname ? "error-border" : ""} // Adds class if empty
              />

              {errors.lname && (
                <span className="error-message-field">{errors.lname}</span>
              )}
            </div>
          </div>

          <div className="form-field">
            <label>
              ID Number <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Type Here"
              name="idno"
              value={formData.idno}
              onChange={handleChange}
              // readOnly
              style={{ backgroundColor: "#f6f6f6" }}
            />
          </div>

          <div className="two-fields">
            <div className="form-field">
              <label>
                Email <span>*</span>
              </label>
              <input
                type="email"
                placeholder="abc@7eagle.com"
                name="email"
                onChange={handleChange}
                value={formData.email}
                // readOnly
                style={{ backgroundColor: "#f6f6f6" }}
                // Adds class if empty
              />
            </div>
          </div>

          <div className="two-fields">
            <div className="form-field">
              <label>
                Phone <span>*</span>
              </label>
              <InputMask
                mask="(999) 999-9999" // Masking for USA phone number
                placeholder="(123) 456-7890"
                name="phone"
                value={formData.phone}
                className={!formData.phone ? "error-border" : "form-input"} // Adds class if empty
                onChange={handleChange}
              />

              {errors.phone && (
                <span className="error-message-field">{errors.phone}</span>
              )}
            </div>
          </div>

          <div className="form-field">
            {isLoaded && (
              <div>
                <label>
                  Location <span>*</span>
                </label>
                <StandaloneSearchBox
                  onLoad={(ref) => (inputref.current = ref)}
                  onPlacesChanged={handleOnPlacesChanged}
                  style={{
                    zIndex: "10000",
                    position: "absolute",
                    top: "0px",
                    width: "100%",
                  }} // Higher z-index for the search box
                >
                  <input
                    type="text"
                    placeholder="Search location"
                    className={!formData.city ? "error-border" : ""}
                    style={{
                      width: "100%",
                      padding: "14px",
                      borderRadius: "10px",
                      border: "1px solid #ddddde",
                    }}
                  />
                </StandaloneSearchBox>
                {errors.city && (
                  <span className="error-message-field">{errors.city}</span>
                )}
              </div>
            )}
          </div>

          {/* Candidate Type Dropdown */}
          <div className="field-wrap form-field">
            <label>
              Candidate Type <span>*</span>
            </label>
            <select
              name="candidateType"
              value={formData.candidateType}
              onChange={handleChange}
              className={!formData.candidateType ? "error-border" : ""}
              style={{
                width: "100%",
                padding: "14px",
                borderRadius: "10px",
                border: "1px solid #ddddde",
                backgroundColor: "#fff",
              }}
            >
              <option value="">Select Candidate Type</option>
              <option value="Transitioning Service Member">
                Transitioning Service Member
              </option>
              <option value="Veteran">Veteran</option>
            </select>
            {errors.candidateType && (
              <span className="error-message-field">
                {errors.candidateType}
              </span>
            )}
          </div>

          <button type="submit" className="btn-save-changes">
            Save
          </button>
        </form>
      </div>
    </>
  );
}

export default UpdateBasicInformation;
