import React from "react";
import { ReactComponent as JobIcon } from "../../../assets/images/jobIcon.svg";

import "./AssignedJobBox.css";
function AssignedJobBox() {
  return (
    <div className="box_count">
      <div className="box_count_profile">
        
        <JobIcon style={{marginRight:"20px"}}/>
      </div>
      <div className="counting_number">
        <h6>
          5 <span>NEW</span>{" "}
        </h6>

        <p>Assigned Jobs</p>
      </div>
    </div>
  );
}

export default AssignedJobBox;
