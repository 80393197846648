import React, { useState } from "react";
import axios from "axios";
import "./FileLists.css";
import { ReactComponent as MoreIcon } from "../../../assets/images/More.svg";


import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";

// import downloadIcon from "../../../assets/images/icon-download.png";
// import viewIcon from "../../../assets/images/view.png";

import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FileList({ candidate }) {
  const [files, setFiles] = useState(
    candidate && candidate.files ? [...candidate.files] : []
  );
  const [showMenu, setShowMenu] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newFileName, setNewFileName] = useState("");
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [viewingFile, setViewingFile] = useState(null); // Track the file being viewed

  const handleMoreClick = (index) => {
    setShowMenu(showMenu === index ? null : index);
    setEditingIndex(null);
  };

  const startRename = (index, currentName) => {
    setEditingIndex(index);
    setNewFileName(currentName);
    setShowMenu(null);
  };

  const handleDownload = async (file) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/candidates/download/${file.fileUrl}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = file.fileUrl;
      link.click();
      window.URL.revokeObjectURL(url);
      setShowMenu(null);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const saveRename = async (index) => {
    const file = files[index];

    try {
      setIsRenaming(true);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/candidates/${candidate._id}/files/rename`,
        {
          fileUrl: file.fileUrl,
          newFileName,
        }
      );

      if (response.status === 200) {
        const updatedFiles = [...files];
        updatedFiles[index].fileName = newFileName;
        updatedFiles[index].fileUrl = response.data.file.fileUrl;
        setFiles(updatedFiles);

        toast.success("File renamed successfully!");
        setEditingIndex(null);
        setShowMenu(null);
      } else {
        toast.error("Failed to rename the file");
      }
    } catch (error) {
      console.error("Error renaming file:", error);
      toast.error("Failed to rename the file. Please try again.");
    } finally {
      setIsRenaming(false);
    }
  };

  const cancelRename = () => {
    setEditingIndex(null);
    setNewFileName("");
  };

  const handleDeleteConfirmation = (file, index) => {
    setFileToDelete({ file, index });
    setIsDeleteModalOpen(true);
    setShowMenu(null);
  };

  const handleDelete = async () => {
    const { file, index } = fileToDelete;

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/candidates/${candidate._id}/files/delete/${file.fileId}`
      );

      if (response.status === 200) {
        const updatedFiles = files.filter((file, i) => i !== index);
        setFiles(updatedFiles);

        toast.success("File deleted successfully!");
        setIsDeleteModalOpen(false);
      } else {
        toast.error("Failed to delete the file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("Failed to delete the file. Please try again.");
      setIsDeleteModalOpen(false);
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const sortedFiles = [...files].sort(
    (a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt)
  );

  // Handle View File button click
  const handleViewFile = (file) => {
    setViewingFile(file); // Set the file being viewed
    setShowMenu(null);
  };

  const handleGoBack = () => {
    setViewingFile(null); // Reset viewing file state
  };

  return (
    <div
      style={{
        width: "100%",
        margin: "20px auto 0px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {viewingFile ? (
        // View File Section
        <div>
          <div className="goback_name">
            <button onClick={handleGoBack}>
              {" "}
              <span>&lt;</span>Go Back
            </button>
            <p>{viewingFile.fileName}</p>
          </div>
          <iframe
            src={`${process.env.REACT_APP_API_URL}${viewingFile.fileUrl}`}
            style={{ width: "100%", height: "500px" }}
            title="File Viewer"
          ></iframe>
        </div>
      ) : (
        // File List Section
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {sortedFiles.map((file, index) => (
            <li key={index} className="file_list">
              {editingIndex === index ? (
                <div className="rename_input">
                  <input
                    type="text"
                    value={newFileName}
                    onChange={(e) => setNewFileName(e.target.value)}
                    style={{ fontSize: "14px", padding: "5px" }}
                    disabled={isRenaming}
                  />
                  <div className="buttons">
                    <button
                      onClick={() => saveRename(index)}
                      disabled={isRenaming}
                    >
                      {isRenaming ? "Saving..." : "Save"}
                    </button>
                    <button onClick={cancelRename} disabled={isRenaming}>
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#555",
                  }}
                >
                  {file.fileName}
                </span>
              )}

              <div style={{ position: "relative" }}>
                <span style={{ fontSize: "14px", color: "#777" }}>
                  {new Date(file.uploadedAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>

           <MoreIcon/>

                {showMenu === index && (
                  <div
                    className="showMenu"
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "0",
                      backgroundColor: "#fff",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      padding: "10px",
                      zIndex: 1000,
                    }}
                  >
                    <ul
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      <li
                        style={{
                          padding: "5px 10px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => startRename(index, file.fileName)}
                      >
                        {/* <img
                          src={EditIcon}
                          alt="edit-icon"
                          style={{ marginRight: "5px" }}
                        /> */}
                        Rename File
                      </li>
                      <li
                        style={{
                          padding: "5px 10px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => handleDownload(file)}
                      >
                        {/* <img
                          src={downloadIcon}
                          alt="download-"
                          style={{ marginRight: "5px" }}
                        /> */}
                        Download File
                      </li>
                      <li
                        style={{
                          padding: "5px 10px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => handleViewFile(file)} // Handle View File click
                      >
                        {/* <img
                          src={viewIcon}
                          alt="view-icon"
                          style={{ marginRight: "5px" }}
                        /> */}
                        View File
                      </li>
                      <li
                        style={{
                          padding: "5px 10px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => handleDeleteConfirmation(file, index)}
                      >
                              <DeleteIcon/>
                 
                        Delete File
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <div className="modal-delete">
          <div className="modal-content">
           <DeleteIcon/>
            <p>Are you sure you want to delete this file?</p>
            <div className="button_delete_cancle">
              <button onClick={handleDelete}>Confirm</button>
              <button onClick={handleCancelDelete}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default FileList;
