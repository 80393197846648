import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext"; // Import AuthContext
import { jwtDecode } from "jwt-decode"; // Import jwtDecode

const TokenDecode = () => {
  const { keycloak, authenticated } = useContext(AuthContext); // Use context to get authentication status
  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    if (authenticated && keycloak) {
      const token = keycloak.token;
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [authenticated, keycloak]);

  return decodedToken;
};

export default TokenDecode;