import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Common/Header/Header";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
// import profilebanner from "../../assets/images/profilebanner1.png";
// import branch_airforce from "../../assets/images/Branch-Air-Force.png";
// import branch_army from "../../assets/images/Branch-Army.png";
// import branch_coast_guard from "../../assets/images/Branch-Coast-Guard.png";
// import branch_marine_corps from "../../assets/images/Branch-Marine-Corps.png";
// import branch_navy from "../../assets/images/Branch-Navy.png";
// import branch_space_force from "../../assets/images/Branch-Space-Force.png";

import axios from "axios";
import "../AdminDashboard.css";
import ProfileWarning from "../../components/Candidates/Profile Warning/ProfileWarning";
import AboutMe from "../../components/Candidates/CandidateAbout/AboutCandidate";
import { ToastContainer } from "react-toastify";
import WatchMyIntroVideo from "../../components/Candidates/WatchMyIntro/WatchMyIntroVideo";
import Certification from "../../components/Candidates/Certifications/Certification";
import TargetIndustries from "../../components/Candidates/Target Industries/TargetIndustries";
import MilitaryBackground from "../../components/Candidates/Military Background/MilitaryBackground";
import ViewResume from "../../components/Candidates/Resume/ViewResume";

const CandidateProfilePage = () => {
  const hideEditIcon = true;
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalResumeOpen, setIsModalResumeOpen] = useState(false);

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true);
  };

  const openResumeModal = () => {
    setIsModalResumeOpen(true);
  };

  const closeResumeModal = () => {
    setIsModalResumeOpen(false);
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const config = {};

        const userId = "67099545f6fe8bcd6e2543d3"; // Example userId
        const response = await axios.get(
          `/api/candidates/user/${userId}`,
          config
        );

        console.log("API Response:", response.data);
        setCandidate(response.data.data);
      } catch (error) {
        console.error(
          "Error during fetch candidate records",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCandidate();
  }, [navigate]);

  if (loading) {
    return (
      <div className="loading-container">
    <Logo style={{width:"150px"}}/>
        <div className="spinner"></div>

        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }
  if (!candidate) {
    navigate("/admin/login"); // Redirect to login
  }

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const profileImage = `${BASE_URL}/${candidate.headshotLink}`;
  return (
    // <div className="dashboard candidate-profile-page">
    //   <Header candidate={candidate} />
    //   <ToastContainer />
    //   <div className="profile-banner-candidate">
    //     <img
    //       src={profilebanner}
    //       alt="profile-baner"
    //       style={{ width: "100%" }}
    //     />

    //     <div className="branch-logo">
    //       {candidate.branch === "Army" && (
    //         <img src={branch_army} alt="branch-army" />
    //       )}

    //       {candidate.branch === "Air Force" && (
    //         <img src={branch_airforce} alt="branch-airforce" />
    //       )}

    //       {candidate.branch === "Navy" && (
    //         <img src={branch_navy} alt="branch-navy" />
    //       )}

    //       {candidate.branch === "Space Force" && (
    //         <img src={branch_space_force} alt="branch-space-force" />
    //       )}

    //       {candidate.branch === "Coast Guard" && (
    //         <img src={branch_coast_guard} alt="branch-coast-guard" />
    //       )}

    //       {candidate.branch === "Marine Corps" && (
    //         <img src={branch_marine_corps} alt="branch-marine-corps" />
    //       )}
    //     </div>
    //   </div>

    //   <div className="container">
    //     <ProfileWarning candidate={candidate} />
    //     <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
    //       <div className="col-lg-3 col-md-3 col-sm-4 col-12 ">
    //         <div className="profile-box-candidate">
    //           <div className="candidate_type">
    //             <span
    //               className="
    //             candidateType"
    //             >
    //               {candidate.type}
    //             </span>
    //           </div>
    //           <div className="candidate-profile-func">
    //             {candidate.headshotLink ? (
    //               <div className="exist-profile-image candidate-profile-img">
    //                 <img
    //                   src={profileImage}
    //                   alt="ProfileImg"
    //                   className="Profile-Img"
    //                 />
    //               </div>
    //             ) : (
    //               <div className="ProfileDefaultImg">
    //                 <h2>{candidate.name.charAt(0)}</h2>
    //               </div>
    //             )}
    //           </div>

    //           <div className="candidate-info">
    //             <h4>{candidate.name}</h4>
    //             <span>{candidate.branch}</span>
    //             <div>
    //               <button
    //                 onClick={openResumeModal}
    //                 className="profile-view-resume"
    //               >
    //                 View Resume
    //               </button>

    //               <div className="Edit-profile-btn-profile">
    //                 <Link to="/admin/dashboard">Edit Profile</Link>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-9 col-md-9 col-sm-8 col-12">
    //         <main>
    //           {candidate.videoIntroUrl && (
    //             <WatchMyIntroVideo
    //               candidate={candidate}
    //               hideEditIcon={hideEditIcon}
    //             />
    //           )}

    //           {(candidate.description ||
    //             candidate.securityClearance ||
    //             candidate.yearsOfExperience ||
    //             candidate.idealJobTitle ||
    //             candidate.relocationprefrence) && (
    //             <AboutMe candidate={candidate} hideEditIcon={hideEditIcon} />
    //           )}

    //           {(candidate.certifications &&
    //             candidate.certifications.length > 0) ||
    //           (candidate.Pursuingcertifications &&
    //             candidate.Pursuingcertifications.length > 0) ? (
    //             <Certification
    //               candidate={candidate}
    //               hideEditIcon={hideEditIcon}
    //             />
    //           ) : null}

    //           {candidate.targetIndustries &&
    //             candidate.targetIndustries.length > 0 && (
    //               <TargetIndustries
    //                 candidate={candidate}
    //                 hideEditIcon={hideEditIcon}
    //               />
    //             )}

    //           {candidate.branch &&
    //             candidate.rank &&
    //             candidate.mos &&
    //             candidate.yearsServed && (
    //               <MilitaryBackground
    //                 candidate={candidate}
    //                 hideEditIcon={hideEditIcon}
    //               />
    //             )}
    //         </main>
    //       </div>
    //     </div>
    //   </div>

    //   <ViewResume
    //     isOpen={isModalResumeOpen}
    //     onClose={closeResumeModal}
    //     candidate={candidate}
    //   />
    // </div>
    <></>
  );
};

export default CandidateProfilePage;
