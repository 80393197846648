import React, { useState } from "react";
import axios from "axios";
import "./ResumeList.css";
import { ReactComponent as MoreIcon } from "../../../assets/images/More.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";


import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResumeList({ candidate }) {
  const [resume, setResume] = useState(
    candidate && candidate.resumeUrl ? candidate.resumeUrl : null
  );
  const [showMenu, setShowMenu] = useState(false);
  const [newFileName, setNewFileName] = useState("");
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [viewingResume, setViewingResume] = useState(false);

  const handleMoreClick = () => {
    setShowMenu(!showMenu);
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/candidates/download/${resume}`,
        { responseType: "blob" }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = resume;
      link.click();
      window.URL.revokeObjectURL(url);
      setShowMenu(false);
    } catch (error) {
      console.error("Error downloading resume:", error);
    }
  };

  const startRename = () => {
    setNewFileName(resume.split("/").pop());
    setShowMenu(false);
  };

  const saveRename = async () => {
    try {
      setIsRenaming(true);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/candidates/${candidate._id}/resume/rename`,
        {
          resumeUrl: resume,
          newFileName,
        }
      );

      if (response.status === 200) {
        setResume(response.data.resumeUrl);
        toast.success("Resume renamed successfully!");
      } else {
        toast.error("Failed to rename the resume");
      }
    } catch (error) {
      console.error("Error renaming resume:", error);
      toast.error("Failed to rename the resume. Please try again.");
    } finally {
      setIsRenaming(false);
    }
  };

  const cancelRename = () => {
    setNewFileName("");
  };

  const handleDeleteConfirmation = () => {
    setIsDeleteModalOpen(true);
    setShowMenu(false);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/candidates/${candidate._id}/resume/delete`
      );

      if (response.status === 200) {
        setResume(null);
        toast.success("Resume deleted successfully!");
        setIsDeleteModalOpen(false);
      } else {
        toast.error("Failed to delete the resume");
      }
    } catch (error) {
      console.error("Error deleting resume:", error);
      toast.error("Failed to delete the resume. Please try again.");
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleViewResume = () => {
    setViewingResume(true);
    setShowMenu(false);
  };

  const handleGoBack = () => {
    setViewingResume(false);
  };

  return (
    <div
      style={{
        width: "100%",
        margin: "0px auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {viewingResume ? (
        <div>
          <button onClick={handleGoBack}>
            <span>&lt;</span>Go Back
          </button>
          <iframe
            src={`${process.env.REACT_APP_API_URL}/${resume}`}
            style={{ width: "100%", height: "500px" }}
            title="Resume Viewer"
          ></iframe>
        </div>
      ) : (
        <div className="resume_list">
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            {resume && candidate.fname}
            {"-"}
            {resume ? resume.split("/").pop() : "No Resume Available"}
          </p>
          {resume && (
            <div style={{ position: "relative" }}>
          <MoreIcon/>
              {showMenu && (
                <div
                  className="showMenu"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "0",
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "10px",
                    zIndex: 1000,
                  }}
                >
                  <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                    <li
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={startRename}
                    >
                      {/* <img
                        src={EditIcon}
                        alt="edit-icon"
                        style={{ marginRight: "5px" }}
                      /> */}
                      Rename Resume
                    </li>
                    <li
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={handleDownload}
                    >
                      {/* <img
                        src={downloadIcon}
                        alt="download-"
                        style={{ marginRight: "5px" }}
                      /> */}
                      Download Resume
                    </li>
                    <li
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={handleViewResume}
                    >
                      {/* <img
                        src={viewIcon}
                        alt="view-icon"
                        style={{ marginRight: "5px" }}
                      /> */}
                      View Resume
                    </li>
                    <li
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={handleDeleteConfirmation}
                    >
                        <DeleteIcon/>
                      Delete Resume
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="modal-delete">
          <div className="modal-content">
           
            <p>Are you sure you want to delete this resume?</p>
            <div className="button_delete_cancle">
              <button onClick={handleDelete}>Confirm</button>
              <button onClick={handleCancelDelete}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default ResumeList;
