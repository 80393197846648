import React, { useState } from "react";
import "./JobSearchStatus.css";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg"; 


import UpdateJobSearchSlider from "./UpdateJobSearchSlider";

function JobSearchStatus({ candidate }) {
  // Track if the update form is displayed instead of job status
  const [isEditing, setIsEditing] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate); // Track updated candidate data

  // Toggle editing state to show/hide the update form
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  // This function will be passed as onUpdate to the child component
  const handleUpdate = (updatedData) => {
    setUpdatedCandidate({
      ...updatedCandidate,
      jobSearchStatus: updatedData.jobSearchStatus,
      jobSearchStatusName: updatedData.jobSearchStatusName,
    });
  };

  // Temporary default fallback (ignoring database value)
  const temporaryDefaultStatusName = "Happily Employed - Not looking."; // Hardcoded fallback
  const jobSearchStatusName =
    updatedCandidate.jobSearchStatusName || temporaryDefaultStatusName;

  return (
    <div className="basicInformation">
      <h4>Job Search Status</h4>

      {isEditing ? (
        <UpdateJobSearchSlider
          candidate={updatedCandidate} // Pass the updated candidate to the child
          onUpdate={handleUpdate} // Callback to handle update in parent
          onClose={toggleEdit} // Close form after submission
        />
      ) : (
        <div className="job-status-container">
          <div className="job-status">
            <h4 className="job_search_number">
              {/* {updatedCandidate.jobSearchStatus || "N/A"} */}
              05
            </h4>
            {/* <p>{jobSearchStatusName}</p> */}
            <p>Happily Employed - Not looking.</p>
          </div>
          <div className="editIcon" onClick={toggleEdit}>
       <EditIcon/>
      
          </div>
        </div>
      )}
    </div>
  );
}

export default JobSearchStatus;
