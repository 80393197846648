import React, { useState } from "react";

import CompanyDetailPopup from "../Company Details/CompanyDetailPopup";
import { ReactComponent as VerifiedIcon } from "../../../assets/images/verified.svg";  

import "./CompanyListTable.css";
const ComapnyListTable = ({ columns, data, admin }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(2); // Default value set to 10
  const [selectedClient, setselectedClient] = useState(null); // State to store selected candidate

  const visibleColumns = columns.filter((col) => col.visible);

  // Pagination logic
  const totalRecords = data.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handle candidate row click
  const handleClientClick = (candidate) => {
    setselectedClient(candidate);
  };


  const getColorForLetter = (letter) => {
    const colors = {
      A: "#FFD757",
      B: "#288C03",
      C: "#03A9F4",
      D: "#FF5722",
      E: "#8E24AA",
      F: "#03DAC5",
      G: "#2196F3",
      H: "#FF4081",
      I: "#9C27B0",
      J: "#4CAF50",
      K: "#FF9800",
      L: "#607D8B",
      M: "#00BCD4",
      N: "#FFEB3B",
      O: "#8BC34A",
      P: "#673AB7",
      Q: "#FF4081",
      R: "#9E9E9E",
      S: "#3F51B5",
      T: "#00BFAE",
      U: "#9E9E9E",
      V: "#4CAF50",
      W: "#3F51B5",
      X: "#E91E63",
      Y: "#FFEB3B",
      Z: "#9C27B0",
    };
    return colors[letter.toUpperCase()] || "rgb(200, 200, 200)";
  };
  // Close the popup
  const closePopup = () => {
    setselectedClient(null);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Handle records per page change
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when records per page is changed
  };

  return (
    <>
<div className="table_candidates company-list-table">
  <table>
    <thead>
      <tr>
        {visibleColumns.map((col, index) => (
          <th
            key={col.id}
            style={index === 0 ? { position: "sticky", left: 0, backgroundColor: "rgb(232 233 235)", zIndex: 1 } : {}}
          >
            {col.name}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {currentRecords.map((row) => (
        <tr key={row.id}>

{visibleColumns.map((col, index) => (
  <td
    key={col.id}
    style={index === 0 ? { position: "sticky", left: 0, backgroundColor: "#fff", zIndex: 1 } : {}}
  >
    {Array.isArray(row[col.field]) ? (
      // Show array length if the field is an array
      <span>{row[col.field].length}</span>
    ) : col.field === "name" ? (
      <>
        {row.logo ? (
          <img
            src={`${row.logo}`}
            alt="Profile"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              marginRight: "8px",
              objectFit: "cover",
            }}
          />
        ) : (
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: getColorForLetter(
                row.name ? row.name.charAt(0) : "?"
              ),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "8px",
            }}
          >
            <span style={{ fontSize: "18px", color: "#fff" }}>
              {row.name ? row.name.charAt(0) : ""}
            </span>
          </div>
        )}
        <span
          onClick={() => handleClientClick(row)}
          style={{ cursor: "pointer" }}
        >
          {row.name}
        </span>
        <VerifiedIcon style={{marginLeft:"5px"}}/>
      </>
    ) : col.field === "manager" ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        {row.profilePhoto ? (
          <img
            src={`${row.profilePhoto}`}
            alt="Manager"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              marginRight: "8px",
              objectFit: "cover",
            }}
          />
        ) : (
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: "#ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "8px",
            }}
          >
            <span style={{ fontSize: "18px", color: "#fff" }}>
              {row.manager && row.manager[0] && row.manager[0].name ? row.manager[0].name.charAt(0) : ""}
            </span>
          </div>
        )}
        <span className="managerName">
          {row.manager && row.manager[0] && row.manager[0].name ? row.manager[0].name : "No Manager"}
        </span>
      </div>
    ) : (
      // Show value for non-array fields
      <span
        className={
          col.field === "userType"
            ? row.userType === "Basic Plan"
              ? "basicPlan"
              : row.userType === "All-Access Plan"
              ? "allAccessPlan"
              : row.userType === "Enterprise Plan"
              ? "enterprisePlan"
              : ""
            : ""
        }
      >
        {row[col.field]}
      </span>
    )}
  </td>
))}

        </tr>
      ))}
    </tbody>
  </table>
</div>


      <div className="pagination-limit">
        <div>
          <label htmlFor="recordsPerPage">Companies per page: </label>
          <select
            id="recordsPerPage"
            value={recordsPerPage}
            onChange={handleRecordsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>

        <div className="pagination_numbers">
          {/* Pagination Controls */}
          {totalRecords > recordsPerPage && (
            <div>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable button if on the first page
              >
                &lt;
              </button>

              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""} // Add active class
                >
                  {index + 1}
                </button>
              ))}

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable button if on the last page
              >
                &gt;
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Render the popup component */}
      {selectedClient && (
        <CompanyDetailPopup
          company={selectedClient}
          onClose={closePopup}
          admin={admin}
        />
      )}
    </>
  );
};

export default ComapnyListTable;
