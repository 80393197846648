// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verification_status_section{
    padding: 20px;
    background-color: white;
    margin-top: 10px;
    border-radius: 10px;
}

.verificaiton_button_list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.verificaiton_button_list p{
    margin-bottom: 0px;
}

.verification_status_section h4{
    font-size: 14px;
}

.verification_list .verifiedIcon{
    margin-left: 0px;
    margin-right: 10px;
}

.verificaiton_button_list button{
    border: 1px solid #034A8C;
    background-color: transparent;
    color: #034A8C;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/Companies/CompanyVerification/CompanyVerification.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".verification_status_section{\n    padding: 20px;\n    background-color: white;\n    margin-top: 10px;\n    border-radius: 10px;\n}\n\n.verificaiton_button_list{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.verificaiton_button_list p{\n    margin-bottom: 0px;\n}\n\n.verification_status_section h4{\n    font-size: 14px;\n}\n\n.verification_list .verifiedIcon{\n    margin-left: 0px;\n    margin-right: 10px;\n}\n\n.verificaiton_button_list button{\n    border: 1px solid #034A8C;\n    background-color: transparent;\n    color: #034A8C;\n    padding: 7px 10px;\n    border-radius: 10px;\n    font-size: 12px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
