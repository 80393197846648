import React, { useState } from "react";
import CandidateDetailsPopup from "../Candidate Details/CandidateDetailsPopup"; // Import the new Popup Component

const CandidateTable = ({ columns, data, admin }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10); // Default value set to 10
  const [selectedCandidate, setSelectedCandidate] = useState(null); // State to store selected candidate
  const [expandedRows, setExpandedRows] = useState([]); // Track expanded rows for jobs

  const visibleColumns = columns.filter((col) => col.visible);

  // Pagination logic
  const totalRecords = data.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handle candidate row click
  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const getColorForLetter = (letter) => {
    const colors = {
      A: "#FFD757", // Purple
      B: "#288C03",
      J: "#034A8C", // Red
      C: "rgb(71 255 171)", // Green
      F: "rgb(71 119 255)", // Blue
      // Add colors for more letters
    };

    return colors[letter.toUpperCase()] || "rgb(200, 200, 200)"; // Default color (gray)
  };

  // Close the popup
  const closePopup = () => {
    setSelectedCandidate(null);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Handle records per page change
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when records per page is changed
  };

  // Toggle expand for jobs
  const toggleExpand = (rowIndex) => {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter((index) => index !== rowIndex));
    } else {
      setExpandedRows([...expandedRows, rowIndex]);
    }
  };

  return (
    <>
      <div className="table_candidates">
        <table>
          <thead>
            <tr>
              {visibleColumns.map((col) => (
                <th key={col.id}>{col.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 ? (
              currentRecords.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <tr>
                    {visibleColumns.map((col) => (
                <td key={col.id}>
                {col.field === "candidateType" ? (
                  <span
                    className={
                      row.candidateType?.toLowerCase() === "veteran"
                        ? "veteran-class"
                        : "transitioning-class"
                    }
                  >
                    {row.candidateType}
                  </span>
                ) : col.field === "fname" ? (
                  <>
                    {row.headshotLink ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${row.headshotLink}`}
                        alt="Profile"
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          marginRight: "8px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            backgroundColor: getColorForLetter(
                              row.fname ? row.fname.charAt(0) : "?"
                            ),
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "8px",
                          }}
                        >
                          <span style={{ fontSize: "18px", color: "#fff" }}>
                            {row.fname ? row.fname.charAt(0) : "?"}
                          </span>
                        </div>
              
                        <span
                          onClick={() => handleCandidateClick(row)}
                          style={{ cursor: "pointer" }}
                        >
                          {row.fname} {row.lname}
                        </span>
                      </div>
                    )}
                  </>
                ) : col.field === "profileProgress" ? (
                  <div style={{ display: "block", alignItems: "center", position: "relative" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color:
                          row.profileProgress === null || row.profileProgress === undefined
                            ? "#e0e0df"
                            : row.profileProgress <= 12
                            ? "red"
                            : row.profileProgress < 100
                            ? "orange"
                            : "#4caf50",
                      }}
                    >
                      {row.profileProgress || 0}%
                    </span>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#e0e0df",
                        borderRadius: "5px",
                        marginRight: "8px",
                      }}
                    >
                      <div
                        style={{
                          width: `${row.profileProgress || 0}%`,
                          backgroundColor:
                            row.profileProgress === null || row.profileProgress === undefined
                              ? "#e0e0df"
                              : row.profileProgress <= 12
                              ? "red"
                              : row.profileProgress < 100
                              ? "orange"
                              : "#4caf50",
                          height: "10px",
                          borderRadius: "5px",
                        }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  row[col.field]
                )}
              </td>
              
                    ))}
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={visibleColumns.length} style={{ textAlign: "center" }}>
                  No records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>


      </div>

      <div className="pagination-limit">
        <div>
          <label htmlFor="recordsPerPage">Candidates per page: </label>
          <select
            id="recordsPerPage"
            value={recordsPerPage}
            onChange={handleRecordsPerPageChange}
          >
            <option value={10}>10</option>

            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>

        <div className="pagination_numbers">
          {/* Pagination Controls */}
          {totalRecords > recordsPerPage && (
            <div>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable button if on the first page
              >
                &lt;
              </button>

              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""} // Add active class
                >
                  {index + 1}
                </button>
              ))}

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable button if on the last page
              >
                &gt;
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Candidate Details Popup */}
      {selectedCandidate && (
        <CandidateDetailsPopup
          candidate={selectedCandidate}
          onClose={closePopup}
        />
      )}
    </>
  );
};

export default CandidateTable;
