// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactCrop {
    max-height: 80vh;
    max-width: 80vw;
  }
  .ReactModal__Overlay {
    background-color: #000000db;
    position: fixed;
    top: 0;
    opacity: 1;
    left: 0;
    z-index: 99999999;
    width: 100%;
    height: 100%;
}

.upload-box{
  border: 2px dashed #ccc;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
  background-color: #F7F8F9;
  border-radius: 10px;
  margin-left: 20px;
}

.upload-heading{
  padding: 10px 20px 5px;
}

.popup-left-heading{
  font-size: 18px;
  font-weight: bold;
}

.uploadImg{
  width: 80px;
}

.left-content-popup{
  padding-right: 20px;
}

.progress-bar{
  border:1px dashed gray;
  padding:10px;
  margin:20px !important;
  text-align: left;
  display: flex !important;
  width:100%;
}

.progress-bar img{
  width: 40px;
  margin-right:20px;
}

.error-message {
  color: red !important;
  font-weight: 600;
  padding-left: 20px;
  text-align: center;
  padding: 15px 0px 15px;
  border: 1px dashed;
  margin: 20px;
  border-radius: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Candidates/ProfileImagePopup/ProfileImagePopup.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;EACjB;EACA;IACE,2BAA2B;IAC3B,eAAe;IACf,MAAM;IACN,UAAU;IACV,OAAO;IACP,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;EAChB,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".ReactCrop {\n    max-height: 80vh;\n    max-width: 80vw;\n  }\n  .ReactModal__Overlay {\n    background-color: #000000db;\n    position: fixed;\n    top: 0;\n    opacity: 1;\n    left: 0;\n    z-index: 99999999;\n    width: 100%;\n    height: 100%;\n}\n\n.upload-box{\n  border: 2px dashed #ccc;\n  padding: 40px 20px;\n  text-align: center;\n  cursor: pointer;\n  background-color: #F7F8F9;\n  border-radius: 10px;\n  margin-left: 20px;\n}\n\n.upload-heading{\n  padding: 10px 20px 5px;\n}\n\n.popup-left-heading{\n  font-size: 18px;\n  font-weight: bold;\n}\n\n.uploadImg{\n  width: 80px;\n}\n\n.left-content-popup{\n  padding-right: 20px;\n}\n\n.progress-bar{\n  border:1px dashed gray;\n  padding:10px;\n  margin:20px !important;\n  text-align: left;\n  display: flex !important;\n  width:100%;\n}\n\n.progress-bar img{\n  width: 40px;\n  margin-right:20px;\n}\n\n.error-message {\n  color: red !important;\n  font-weight: 600;\n  padding-left: 20px;\n  text-align: center;\n  padding: 15px 0px 15px;\n  border: 1px dashed;\n  margin: 20px;\n  border-radius: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
