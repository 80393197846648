import React, { useState, useEffect } from "react";
import axios from "axios";
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";

import Header from "../../components/Common/Header/Header";
import Sidebar from "../../components/Common/Sidebar/Sidebar";
import "../AdminDashboard.css";
import AddCandidateForm from "../../components/Candidates/Add New Candidates/AddNewCandidateForm";
import TokenDecode from "../../context/DecodeJWT";
const AddNewCandidatesPage = () => {
  const decodedToken = TokenDecode();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [candidateData, setCandidateData] = useState([]); // Initialize candidate data as empty array
  const [filteredData, setFilteredData] = useState([]);

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };
  // Fetch candidate data from API
  useEffect(() => {
    const fetchCandidateData = async () => {
      try {
        const response = await axios.get(`/api/admin/candidates`);
        setCandidateData(response.data); // Set fetched data to candidateData
        setFilteredData(response.data); // Initialize filteredData with the fetched data
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    fetchCandidateData();
  }, []);

  // Filter candidate data based on search term
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = candidateData.filter((candidate) =>
      Object.values(candidate).some(
        (value) => String(value).toLowerCase().includes(lowerCaseSearchTerm) // Safely convert to string
      )
    );
    setFilteredData(filtered);
  }, [searchTerm, candidateData]);

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        setLoading(true);
        const userEmail = decodedToken?.email;
     
      if (userEmail) {
          const response = await axios.get(`/api/admin/?email=${userEmail}`);
          setAdmin(response.data);
          console.log(response.data.email
          );
        }
      } catch (error) {
        console.log(
          "Error fetching Admin:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAdmin();
  }, [decodedToken]);

  if (loading) {
    return (
      <div className="loading-container">
        <Logo style={{width:"150px"}}/>
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Header admin={admin} />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar onToggleSidebar={handleToggleSidebar} admin={admin} />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            <div className="main_heading_dashboard-add-new">
              <h4 style={{ color: "black" }}>Add New Candidate</h4>
              <AddCandidateForm />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AddNewCandidatesPage;
