import React, { createContext, useState, useEffect, useRef } from "react";
import Keycloak from "keycloak-js";
import { useNavigate, useLocation } from "react-router-dom";
import logger from '../utils/logger'; // Adjust the path as necessary
import axios from 'axios';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const keycloak = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!keycloak.current) {
      logger.info("Initializing Keycloak...");
      const keycloakConfig = {
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        "public-client": 'true',
        "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
        "verify-token-audience": process.env.REACT_APP_KEYCLOAK_VERIFY_TOKEN_AUDIENCE === 'true',
        "use-resource-role-mappings": process.env.REACT_APP_KEYCLOAK_USE_RESOURCE_ROLE_MAPPINGS === 'true',
        "confidential-port": parseInt(process.env.REACT_APP_KEYCLOAK_CONFIDENTIAL_PORT, 10),
      };
      //logger.info("Keycloak Config: " + JSON.stringify(keycloakConfig));
      keycloak.current = new Keycloak(keycloakConfig);
      keycloak.current.init({ 
        pkceMethod: 'S256', 
        onLoad: 'login-required',
       }).then(authenticated => {
        setAuthenticated(authenticated);
        //logger.info("Keycloak initialized: " + authenticated);
      }).catch(error => {
        logger.error("Failed to initialize Keycloak: " + error);
      });
    }
  }, []);

  // set axios defaults when authenticated
  useEffect(() => {
    if (keycloak.current && authenticated && keycloak.current.token) {
      // Set the default Authorization header for axios
      axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.current.token}`;
      //logger.info('Axios default Authorization header set');
    }
  }, [authenticated]); // Re-run when 'authenticated' state changes

  useEffect(() => {
    logger.info("Checking authentication state...");
    if (keycloak.current) {
      keycloak.current.onAuthSuccess = () => {
        logger.info("Authentication successful");
        setAuthenticated(true);
      };
  
      keycloak.current.onAuthError = () => {
        logger.error("Authentication error");
        setAuthenticated(false);
      };
  
      keycloak.current.onAuthLogout = () => {
        logger.info("Logged out");
        setAuthenticated(false);
      };
  
      keycloak.current.onTokenExpired = () => {
        logger.info('Token expired, refreshing...');
        keycloak.current
          .updateToken(70) // Refresh if token will expire in 70 seconds or less
          .then((refreshed) => {
            if (refreshed) {
              logger.info('Token refreshed');
              // Update axios defaults with the new token
              axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.current.token}`;
            } else {
              logger.info('Token not refreshed, redirecting to login');
              keycloak.current.login();
            }
          })
          .catch(() => {
            logger.error('Failed to refresh token, redirecting to login');
            keycloak.current.login();
          });
      };
    }
  }, [keycloak.current]);

  const login = () => {
    if (keycloak.current) {
      logger.info("Redirecting to Keycloak login...");
      keycloak.current.login();
    }
  };

  const logout = () => {
    if (keycloak.current) {
      logger.info("Logging out from Keycloak...");
      keycloak.current.logout();
    }
  };


  return (
    <AuthContext.Provider
      value={{ keycloak: keycloak.current, authenticated, login, logout }}
    >
      {keycloak.current && children}
    </AuthContext.Provider>
  );
};