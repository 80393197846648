// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_company_sec{
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;

}

.about_company_sec .about_heading{
    display: flex;
    justify-content: space-between;
}

.about_company_sec .about_heading h4{
    font-size: 14px;
}

.about_detail p{
    font-size: 14px;
}

.about_detail p:first-child{
    margin-bottom: 2px;
}

.about_details{
    padding-top: 10px;
}

.editIcon-new{
    width: 25px;
    height: 25px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Companies/AboutCompany/AboutCompany.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;;AAEpB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".about_company_sec{\n    padding: 20px;\n    background-color: white;\n    border-radius: 10px;\n    margin-top: 10px;\n\n}\n\n.about_company_sec .about_heading{\n    display: flex;\n    justify-content: space-between;\n}\n\n.about_company_sec .about_heading h4{\n    font-size: 14px;\n}\n\n.about_detail p{\n    font-size: 14px;\n}\n\n.about_detail p:first-child{\n    margin-bottom: 2px;\n}\n\n.about_details{\n    padding-top: 10px;\n}\n\n.editIcon-new{\n    width: 25px;\n    height: 25px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
