import React, { useEffect, useState } from "react";
import Modal from "react-modal"; // React Modal import
import "./UploadResume/UploadResume.css"; // Custom CSS for animation
import { toast, ToastContainer } from "react-toastify";
Modal.setAppElement("#root");

const ViewRedactedResume = ({ isOpen, onClose, candidate, RedactedResume }) => {
  const [resumeUrlLink, setResumeUrlLink] = useState(
    candidate.redactedResumeUrl
  );

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const profileUrlLink = `${BASE_URL}/${candidate.headshotLink}`;

  // If RedactedResume (File) is passed, convert it to URL
  useEffect(() => {
    if (RedactedResume) {
      // Check if RedactedResume is an instance of File
      if (RedactedResume instanceof File) {
        const fileUrl = URL.createObjectURL(RedactedResume); // Convert file to URL
        setResumeUrlLink(fileUrl); // Update state with the file URL
      } else {
        // Ensure BASE_URL is appended correctly if RedactedResume is a string (URL)
        const finalUrl = RedactedResume.startsWith("http")
          ? RedactedResume // If it's already a full URL, use it
          : `${BASE_URL}/${RedactedResume}`; // Otherwise, append BASE_URL
        setResumeUrlLink(finalUrl);
      }
    } else if (candidate.redactedResumeUrl) {
      // If RedactedResume is not passed, use candidate's default redactedResumeUrl
      setResumeUrlLink(`${BASE_URL}/${candidate.redactedResumeUrl}`);
    }
  }, [RedactedResume, candidate.redactedResumeUrl]);

  const [modalClass, setModalClass] = useState("modal-hidden");

  // Update modal class based on isOpen prop
  useEffect(() => {
    if (isOpen) {
      setModalClass("modal-visible");
    } else {
      setModalClass("modal-hidden");
    }
  }, [isOpen]);

  const customStyles = {
    content: {
      top: "50%",
      bottom: "auto",
      position: "absolute",
      borderRadius: "10px",
      padding: "0px",
      width: "35%",
      left: "65%",
      top: "0",
      minHeight: "100vh",
      transition: "transform 0.5s ease, opacity 0.5s ease", // Smooth slide and fade effect
      backgroundColor: "white",
    },
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        overlayClassName="modal-overlay" // For background fade
        className={modalClass} // Applying animation class
      >
        <div className="popup-content">
          <button onClick={onClose} className="close-btn-resume">
            Close
          </button>

          <div className="popup-header">
            <div className="profile">
              {candidate.headshotLink ? (
                <img
                  src={profileUrlLink}
                  alt="headshot-img"
                  className="resume-img"
                />
              ) : (
                <div className="ProfileDefaultImg resume-default-img">
                  <h2>{candidate.fname.charAt(0)}</h2>
                </div>
              )}

              <div className="profile-detail">
                <h2 className="resume-candidate-name">{candidate.fname}</h2>
                <div style={{ display: "flex" }}>
                  <h6>{candidate.branch}</h6>
                  <span className="resume-type">{candidate.type}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Conditionally render iframe only if resumeUrlLink exists */}
          {resumeUrlLink && (
            <iframe
              src={resumeUrlLink}
              title="Resume"
              style={{
                width: "100%",
                height: "700px",
                border: "none",
                marginTop: "20px",
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ViewRedactedResume;
