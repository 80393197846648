import React, { useState, useEffect, useRef } from "react";
import "./CompanyInfo.css";
import { ReactComponent as MoreIcon } from "../../../assets/images/More.svg"; 
import { ReactComponent as VerifiedIcon } from "../../../assets/images/verified.svg";  
import { ReactComponent as ExternalLinkIcon } from "../../../assets/images/ExternalLink.svg"; 
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure you import the CSS

import {
  MdOutlineEmail,
  MdOutlinePhone,
  MdOutlineLocationOn,
} from "react-icons/md";

import UpdateBasicInformation from "../Profile Information/UpdateBasicInformationPopup";

function CompanyInfo({ company, toggleSubmitForm }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCompany, setUpdatedCompany] = useState(company);
  const [isManagerDropdownOpen, setIsManagerDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [managers, setManagers] = useState(["Jordie", "Jethro", "Tyler"]); // Sample list of managers
  const [selectedManagers, setSelectedManagers] = useState(
    company.managers || []
  );

  const dropdownRef = useRef(null); // Ref for main dropdown
  const managerDropdownRef = useRef(null); // Ref for manager dropdown

  // Calculate Progress Percentage
  const hasBasicInfo =
    company.fname &&
    company.lname &&
    company.phone &&
    company.city &&
    company.state;

  const hasMilitaryBackground =
    company.branch && company.rank && company.mos && company.yearsServed;

  const hasCompleteProfileInfo =
    company.description &&
    company.securityClearance &&
    company.yearsOfExperience &&
    company.idealJobTitle &&
    company.relocationprefrence;

  const hasProfilePhoto = company.headshotLink;

  const totalSections = 4;
  let completedSections = 0;

  if (hasBasicInfo) completedSections++;
  if (hasMilitaryBackground) completedSections++;
  if (hasProfilePhoto) completedSections++;
  if (hasCompleteProfileInfo) completedSections++;

  const progress = (completedSections / totalSections) * 100;

  const toggleForm = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleUpdate = (updatedData) => {
    setUpdatedCompany({
      ...updatedCompany,
      fname: updatedData.fname,
      lname: updatedData.lname,
      email: updatedData.email,
      phone: updatedData.phone,
      idno: updatedData.idno,
      state: updatedData.state,
      city: updatedData.city,
      candidateType: updatedData.candidateType,
    });
  };

  const getColorForLetter = (letter) => {
    const colors = {
      A: "#FFD757", // Purple
      B: "#288C03",
      J: "#034A8C", // Red
      C: "rgb(71 255 171)", // Green
      F: "rgb(71 119 255)", // Blue
      // Add colors for more letters
    };

    return colors[letter.toUpperCase()] || "rgb(200, 200, 200)"; // Default color (gray)
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleManagerDropdown = () => {
    setIsManagerDropdownOpen(!isManagerDropdownOpen);
  };

  const handleManagerSelect = (manager) => {
    if (selectedManagers.includes(manager)) {
      setSelectedManagers(selectedManagers.filter((m) => m !== manager));
    } else if (selectedManagers.length < 2) {
      setSelectedManagers([...selectedManagers, manager]);
    } else {
      toast.error("You can select a maximum of 2 managers.");
    }
  };

  const handleSaveManagers = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(
        `${apiUrl}/api/admin/candidates/addManager/${company._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ managers: selectedManagers }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData.message);
        toast.error("Error:", errorData.message);
      } else {
        toast.success("Manager Added Successful!");
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert("An error occurred while updating managers.");
      toast.error("An error occurred while updating managers");
    }
  };

  const filteredManagers = managers.filter((manager) =>
    manager.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Close dropdowns on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }

      if (
        isManagerDropdownOpen &&
        managerDropdownRef.current &&
        !managerDropdownRef.current.contains(event.target)
      ) {
        setIsManagerDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, isManagerDropdownOpen]);

  return (
    <div>
      <ToastContainer />
      {!isModalOpen ? (
        <div className="profile_info_candidate_section">
          <div className="profile_visiblity">
            <div>
              <p>
                Profile Visibility: <b>Public</b>
              </p>
            </div>
            <div className="open_new_window">
              <a href="">
                Open in new Window{" "}
               <ExternalLinkIcon/>

              
              </a>
            </div>
          </div>

          <div className="profile_info_candidates">
            <div className="profile_info_detail">
              {/* <img
                src={`${company.logo}`}
                className="profile_img profile_logo"
              /> */}

<div className="company-logo">
  {company.logo ? (
    <img
      src={company.logo}
      alt="Profile Logo"
      className="profile_img profile_logo"
    />
  ) : (
    <div
      style={{
        width: "80px",
        height: "80px",
        borderRadius: "50%",
        backgroundColor: getColorForLetter(company.name ? company.name.charAt(0) : "?"),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "8px",
      }}
    >
      <span style={{ fontSize: "24px", color: "#fff" }}>
        {company.name ? company.name.charAt(0) : "?"}
      </span>
    </div>
  )}
</div>


              <div className="candidate_info_name">
                <h2>
                  {updatedCompany.name || company.name}

           
                  <VerifiedIcon style={{marginLeft:"5px"}}/>
                </h2>

                <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                  <MdOutlineLocationOn
                    style={{
                      color: "#4A4D50",
                      fontSize: "18px",
                      marginRight: "5px",
                    }}
                  />
                  {updatedCompany.city || company.city},{" "}
                  {updatedCompany.state || company.state}
                </p>
              </div>
            </div>

            <div className="more_icon" ref={dropdownRef}>
             <MoreIcon/>

              {isDropdownOpen && (
                <div className="dropdown-edit-profile">
                  <button onClick={toggleForm}>Edit Info</button>
                  <button onClick={toggleSubmitForm}>Present to Company</button>
                  <button>Add to Hot Candidates</button>
                  <button>Mark as hired</button>
                </div>
              )}
            </div>
          </div>

          <div className="profile_info_details company_plan">
            <div className="location_address">
              <b>Current Plan</b>
              <p>
                {updatedCompany.userType === "Free" ||
                company.userType === "Free"
                  ? "Basic Plan"
                  : updatedCompany.userType || company.userType}
              </p>
            </div>

            <div className="profile_complete">
              <p className="account_progress">Profile Progress</p>
              <h6>{Math.round(progress)}%</h6>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#e0e0df",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: `${progress}%`,
                    height: "7px",
                    backgroundColor: "#4caf50",
                    transition: "width 0.5s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="candidate_managerer_affiliate"
            ref={managerDropdownRef}
          >
            <div className="managers_affiliate">
              <p>
                Owner{" "}
                <span>
                  {selectedManagers.length > 0
                    ? selectedManagers.join(", ")
                    : company.managers && company.managers.length > 0
                    ? company.managers.join(", ")
                    : "None"}{" "}
                  <b
                    onClick={toggleManagerDropdown}
                    style={{ cursor: "pointer" }}
                  >
                    ▼
                  </b>
                </span>
              </p>
            </div>

            <div className="addmanager">
              <button
                className="add_manager_btn"
                onClick={toggleManagerDropdown}
              >
                Add Manager
              </button>
              {isManagerDropdownOpen && (
                <div className="dropdown-manager">
                  <input
                    type="text"
                    placeholder="Search manager..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-manager"
                  />
                  <ul>
                    {filteredManagers.map((manager, index) => (
                      <li
                        key={index}
                        onClick={() => handleManagerSelect(manager)}
                        style={{
                          backgroundColor: selectedManagers.includes(manager)
                            ? "rgba(224, 224, 224, 0.47)"
                            : "transparent",
                        }}
                      >
                        {manager}
                      </li>
                    ))}
                  </ul>
                  <button className="saveManager" onClick={handleSaveManagers}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <UpdateBasicInformation
          candidate={updatedCompany}
          onUpdate={handleUpdate}
          isOpen={isModalOpen}
          onClose={toggleForm}
        />
      )}
    </div>
  );
}

export default CompanyInfo;
