import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "../../../assets/images/DashboardIcon.svg";
import { ReactComponent as JobIcon } from "../../../assets/images/icon-job-fill.svg";
import { ReactComponent as CompanyIcon } from "../../../assets/images/icon-company-fill.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/icon-user-fill.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/images/icon-collapse-fill.svg";
import "../Sidebar/Sidebar.css";

const Sidebar = ({ onToggleSidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();

  // Ensure the sidebar remains collapsed for screen sizes <= 991px
  const checkScreenSize = () => {
    if (window.innerWidth <= 1024) {
      setIsCollapsed(true); // Always collapse on small screens
    } else {
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    // Run the function on initial load
    checkScreenSize();

    // Add resize listener to handle window resizing
    window.addEventListener("resize", checkScreenSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const toggleSidebar = () => {
    if (window.innerWidth > 1024) {
      // Only allow toggling on larger screens
      setIsCollapsed(!isCollapsed);
      onToggleSidebar(!isCollapsed);
    }
  };

  return (
    <aside className={isCollapsed ? "collapsed" : ""}>
      <ul style={{ marginTop: "10px" }}>
        <li
          className={location.pathname === "/admin/dashboard" ? "active" : ""}
        >
          <Link to="/admin/dashboard">
            <DashboardIcon className="sidebar-icons" />
            {!isCollapsed && "Dashboard"}
          </Link>
        </li>
        <li
          className={location.pathname === "/admin/candidates" ? "active" : ""}
        >
          <Link to="/admin/candidates">
           <UserIcon className="sidebar-icons"/>
            {!isCollapsed && "Candidates"}
          </Link>
        </li>
        <li className={location.pathname === "/admin/jobs" ? "active" : ""}>
          <Link to="/admin/jobs">
          <JobIcon className="sidebar-icons" />
            {!isCollapsed && "Jobs"}
          </Link>
        </li>
        <li
          className={location.pathname === "/admin/companies" ? "active" : ""}
        >
          <Link to="/admin/companies">
        <CompanyIcon className="sidebar-icons"/>
            {!isCollapsed && "Companies"}
          </Link>
        </li>
      </ul>
      <div className="toggle-btn">
        <div onClick={toggleSidebar} className="collapse-btn">
          {isCollapsed ? (
            <CollapseIcon  className="sidebar-icons"/>
          ) : (
            <>
          
              <CollapseIcon  className="sidebar-icons"/>
              {" "}
              Collapse Menu{" "}
            </>
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
