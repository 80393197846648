// src/components/PrivateRoute.js
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext"; // Import AuthContext
import { Navigate } from "react-router-dom";
import logger from '../utils/logger'; // Adjust the path as necessary

const PrivateRoute = ({ children }) => {
  const { keycloak, authenticated } = useContext(AuthContext); // Use context to get authentication status

  useEffect(() => {
    logger.info("Checking if user is authenticated...");
    if (!authenticated && keycloak) {
      logger.info("User not authenticated, redirecting to Keycloak login");
      keycloak.login(); // Redirect to Keycloak login
    }
  }, [authenticated, keycloak]);

  if (!keycloak) {
    console.log('waiting for keycloak');
    return <div>Loading...</div>; // Show a loading indicator while Keycloak is initializing
  }
  if (keycloak && !keycloak.token) {
    console.log('waiting for bearer token');
    return <div>Loading...</div>; // Show a loading indicator while Keycloak is initializing
    
  }

  return authenticated ? children : <Navigate to="/" />; // Render children if authenticated, otherwise redirect to home
};

export default PrivateRoute;