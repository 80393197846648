// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-military .form-field {
    display: block;
    width: 100%;
    padding-bottom: 15px;
}

.add-military input{
    padding:14px;
}

.popup-form{
    
    padding:20px 0px 5px 0px !important;
}

.form-field label{
    font-size: 14px
}`, "",{"version":3,"sources":["webpack://./src/components/Candidates/Military Background/Add Military/AddMilitary.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,mCAAmC;AACvC;;AAEA;IACI;AACJ","sourcesContent":[".add-military .form-field {\n    display: block;\n    width: 100%;\n    padding-bottom: 15px;\n}\n\n.add-military input{\n    padding:14px;\n}\n\n.popup-form{\n    \n    padding:20px 0px 5px 0px !important;\n}\n\n.form-field label{\n    font-size: 14px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
