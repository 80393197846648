// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit_company_section{
    background-color: white;
 
    border-radius: 10px;
    box-shadow: 0px 1px 2px #e0e2e5;
}

.submit_company_section .submit_header{
   
border-bottom: 1px solid #b0b7bf;
}

.submit_company_section .submit_header h3{
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
    margin-bottom: 0px !important;
}

.submit_body{
    padding: 20px;
    border-bottom: 1px solid #b0b7bf;
}

.submit_body label{
    display: block;
    padding-bottom: 10px;
    color: #4A4D50;
    font-size: 14px;
    font-weight: 600;
}

.submit_body input{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b0b7bf;
    margin-bottom: 10px;
}

.submit_body textarea{
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b0b7bf;
    min-height: 130px;
  
}

.submit_footer{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}
.submit_footer button{
    background-color:#ffc500 ;
    border: none;
    padding: 12px 15px;
    border-radius: 10px;
    font-size: 14px;
}
.submit_footer button:first-child{
    margin-right: 10px;
    background-color: transparent;
    color: #4A4D50;
    border: none;
    padding: 10px 15px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Candidates/SubmitToCompany/SubmitToCompany.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;;IAEvB,mBAAmB;IACnB,+BAA+B;AACnC;;AAEA;;AAEA,gCAAgC;AAChC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;;AAErB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".submit_company_section{\n    background-color: white;\n \n    border-radius: 10px;\n    box-shadow: 0px 1px 2px #e0e2e5;\n}\n\n.submit_company_section .submit_header{\n   \nborder-bottom: 1px solid #b0b7bf;\n}\n\n.submit_company_section .submit_header h3{\n    font-size: 20px;\n    font-weight: 600;\n    padding: 20px;\n    margin-bottom: 0px !important;\n}\n\n.submit_body{\n    padding: 20px;\n    border-bottom: 1px solid #b0b7bf;\n}\n\n.submit_body label{\n    display: block;\n    padding-bottom: 10px;\n    color: #4A4D50;\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.submit_body input{\n    width: 100%;\n    padding: 10px;\n    border-radius: 10px;\n    border: 1px solid #b0b7bf;\n    margin-bottom: 10px;\n}\n\n.submit_body textarea{\n    padding: 10px;\n    border-radius: 10px;\n    border: 1px solid #b0b7bf;\n    min-height: 130px;\n  \n}\n\n.submit_footer{\n    padding: 20px;\n    display: flex;\n    justify-content: flex-end;\n}\n.submit_footer button{\n    background-color:#ffc500 ;\n    border: none;\n    padding: 12px 15px;\n    border-radius: 10px;\n    font-size: 14px;\n}\n.submit_footer button:first-child{\n    margin-right: 10px;\n    background-color: transparent;\n    color: #4A4D50;\n    border: none;\n    padding: 10px 15px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
