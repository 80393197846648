import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddNewUserForm.css";

const AddNewUserForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      firstName,
      lastName,
      email,
      phone,
      role,
    };

    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      // Sending POST request to add a new user
      const response = await fetch(`${apiUrl}/api/admin/users/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("User added successfully!");
        // Reset form fields after submission
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setRole("");
      } else {
        toast.error("Error adding user.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Network error, please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="add_user_section">
      <ToastContainer />
      <div className="add_user_header">
        <h3>Add New User</h3>
      </div>

      <div className="add_user_body">
        <form>
          <div>
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              placeholder="Type here"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>

          <div>
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
                 placeholder="Type here"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>

          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
                 placeholder="Type here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div>
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
                 placeholder="Type here"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div>
  <label htmlFor="role">Role</label>
  <select
    id="role"
    value={role}
    onChange={(e) => setRole(e.target.value)}
    required
  >
    <option value="">Select a role</option>
    <option value="admin">Admin</option>
    <option value="user">User</option>
    <option value="moderator">Moderator</option>
    {/* Add more options as needed */}
  </select>
</div>

        </form>
      </div>

      <div className="add_user_footer">
        <button
          type="button"
          onClick={() => {
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setRole("");
          }}
        >
          Cancel
        </button>
        <button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Adding..." : "Add User"}
        </button>
      </div>
    </div>
  );
};

export default AddNewUserForm;
