import React from "react";

import { ReactComponent as CandidateIcon } from "../../../assets/images/candidateIcon.svg";

import "./AssignedCandidateBox.css";
function AssignedCandidateBox({ candidateData }) {
  return (
    <div className="box_count">
      <div className="box_count_profile">
            <CandidateIcon style={{marginRight:"20px"}}/>
      </div>
      <div className="counting_number">
        <h6>
          {candidateData.length} <span>NEW</span>{" "}
        </h6>

        <p>Assigned Candidates</p>
      </div>
    </div>
  );
}

export default AssignedCandidateBox;
