import React, { useState, useEffect, useRef } from "react";
import "./AddNewFilter.css"; // For styling the modal

// Import icons


import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";
import { ReactComponent as SettingIcon } from "../../../assets/images/setting.svg";
import { ReactComponent as IconJob } from "../../../assets/images/icon-job-fill.svg";
import { ReactComponent as IconSearch } from "../../../assets/images/icon-search-fill.svg";
import { ReactComponent as IconDate } from "../../../assets/images/Date_fill.svg";
import { ReactComponent as IconPin } from "../../../assets/images/icon-pin-fill.svg";
import { ReactComponent as IconShield } from "../../../assets/images/icon-shield-fill.svg";
import { ReactComponent as IconAward } from "../../../assets/images/icon-award-fill.svg";
import { ReactComponent as IconIndustry } from "../../../assets/images/icon-industry-fill.svg";
import { ReactComponent as IconCompany } from "../../../assets/images/icon-company-fill.svg";
import { ReactComponent as IconFire } from "../../../assets/images/icon-fire-fill.svg";
import { ReactComponent as IconUserAdd } from "../../../assets/images/icon-useradd-fill.svg";
import { ReactComponent as IconActivity } from "../../../assets/images/icon-useractivity-fill.svg";
import { ReactComponent as IconInActivity } from "../../../assets/images/icon-userinactive-fill.svg";


const AddFilterModal = ({ isOpen, onClose, onFilterClick }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const modalRef = useRef(null); // Reference for the modal content

  // Map filters to icons
  const filterIcons = {
    Manager: IconUser,
    "Text Search": IconSearch,
    "Company Name": IconUserAdd,
    "Current Plan": IconShield,
    "Verification Status": IconJob,
    "Availability Date": IconDate,
    "Radius Search (Location)": IconPin,
    "Security Clearance": IconShield,
    Certifications: IconAward,
    "Target Industries": IconIndustry,
    "Relocation Option": IconPin,
    Company: IconCompany,
    Job: IconJob,
    Stage: SettingIcon,
    "Hot Candidate": IconFire,
    Affiliate: IconUser,
    "Last Activity": IconActivity,
    "Show Inactive Candidates": IconInActivity,
    "Date Joined": IconDate,
  };

  const allFilters = Object.keys(filterIcons);

  // Filter options based on search input
  const filteredFilters = allFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close the modal if clicked outside
      }
    };

    // Add event listener when modal is open
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null; // Do not render modal if not open

  return (
    <div className="modal-main">
      <div className="modal-content" ref={modalRef}>
        <input
          type="text"
          placeholder="Type or select a filter"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        <div className="modal-body">
          {/* Filter List */}
          {/* <ul className="filter-list">
            {filteredFilters.map((filter, index) => (
              <li
                key={index}
                className="filter-item"
                onClick={() => onFilterClick(filter)} // Handle click on filter
              >
                <img
                  src={filterIcons[filter]} // Icon for the filter
                  alt={`${filter} icon`}
                  className="filter-icon" // Add CSS class for styling icons
                />
                {filter}
              </li>
            ))}
          </ul> */}

<ul className="filter-list">
  {filteredFilters.map((filter, index) => (
    <li
      key={index}
      className="filter-item"
      onClick={() => onFilterClick(filter)} // Handle click on filter
    >
      {filterIcons[filter] && (
        <span className="filter-icon">
          {React.createElement(filterIcons[filter])} {/* Render the SVG as a component */}
        </span>
      )}
      {filter}
    </li>
  ))}
</ul>

        </div>
      </div>
    </div>
  );
};

export default AddFilterModal;
