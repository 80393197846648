import React, { useState } from "react";
// import "./ProfileImageUpload.css";
import "./AddNewCandidateResume.css";
import { ReactComponent as UploadIcon } from "../../../assets/images/uploadIcon.svg";
import fileImg from "../../../assets/images/file.png";
import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";
function AddNewCandidateResume({ candidate, onClose, onFileSelect }) {
  const [file, setFile] = useState(null); // State for the resume file
  const [error, setError] = useState(""); // State for error handling

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "application/pdf" ||
        selectedFile.type === "application/msword" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setError("");
      setFile(selectedFile); // Store the resume file in the state
      onFileSelect(selectedFile); // Pass file to the parent component immediately
    } else {
      setError(
        "You can’t upload this file. Only .pdf, .doc, and .docx formats are allowed."
      );
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (
      droppedFile &&
      (droppedFile.type === "application/pdf" ||
        droppedFile.type === "application/msword" ||
        droppedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setError("");
      setFile(droppedFile); // Store the resume file in the state
      onFileSelect(droppedFile); // Pass file to the parent component immediately
    } else {
      setError(
        "You can’t upload this file. Only .pdf, .doc, and .docx formats are allowed."
      );
    }
  };

  return (
    <>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "600",
          marginBottom: "5px",
          marginTop: "5px",
        }}
      >
        Resume
      </p>
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-6">
          <div
            className="upload-box upload_new_candidate_resume"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            {file ? (
              <div className="file-preview">
                <img src={fileImg} alt="File Icon" className="fileIcon" />
                <p>{file.name}</p>
              </div>
            ) : (
              <>
             <UploadIcon/>
                <p
                  style={{
                    padding: "10px",
                    fontWeight: "600",
                    fontSize: "16px",
                    paddingBottom: "0px",
                    margin: "0px",
                  }}
                >
                  Drag & drop files or{" "}
                  <label
                    htmlFor="file-upload"
                    className="browse-link"
                    style={{ cursor: "pointer " }}
                  >
                    <b> Browse</b>
                  </label>
                </p>
                <p style={{ fontSize: "12px" }}>
                  Supported formats: .pdf, .doc, .docx
                </p>
              </>
            )}
            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="col-lg-6"></div>
      </div>

      {error && <p className="error-message">{error}</p>}
    </>
  );
}

export default AddNewCandidateResume;
