// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pipeline_sec{
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: white;
}

.pipeline_sec h4{
    font-size: 14px;
    padding-bottom: 10px;
}

.pipeline_sec .pipeline_list{
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    background-color: #e0e0df;
    margin-bottom: 10px;
    cursor: pointer;
}

.pipeline_sec .pipeline_list:first-child{
    margin-top: 20px;
}

.pipeline_sec .submitted_candidate, .pipeline_sec .total_applicants {
    background-color: #E6F3FF;
}

.pipeline_sec .disqualified_applicants{
    background-color: #FFB2B2;
    color:#6B0202
}

.pipeline_sec .sent_offer{
    background-color: #EDFFE6;
    color: green;
}

.pipeline_sec .hired{
    background-color: #C6FFB2;
    color: green;
}`, "",{"version":3,"sources":["webpack://./src/components/Companies/PipeLine/PipeLine.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB;AACJ;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".pipeline_sec{\n    padding: 20px;\n    border-radius: 10px;\n    margin-top: 10px;\n    background-color: white;\n}\n\n.pipeline_sec h4{\n    font-size: 14px;\n    padding-bottom: 10px;\n}\n\n.pipeline_sec .pipeline_list{\n    padding: 5px 10px;\n    border-radius: 5px;\n    font-size: 12px;\n    display: flex;\n    justify-content: space-between;\n    background-color: #e0e0df;\n    margin-bottom: 10px;\n    cursor: pointer;\n}\n\n.pipeline_sec .pipeline_list:first-child{\n    margin-top: 20px;\n}\n\n.pipeline_sec .submitted_candidate, .pipeline_sec .total_applicants {\n    background-color: #E6F3FF;\n}\n\n.pipeline_sec .disqualified_applicants{\n    background-color: #FFB2B2;\n    color:#6B0202\n}\n\n.pipeline_sec .sent_offer{\n    background-color: #EDFFE6;\n    color: green;\n}\n\n.pipeline_sec .hired{\n    background-color: #C6FFB2;\n    color: green;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
