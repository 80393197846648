import React, { useState } from "react";
import "./AddNewCompanyForm.css"; // Import the CSS file
import { ReactComponent as UploadIcon } from "../../../assets/images/uploadIcon.svg";


function AddNewCompanyForm() {
  const [users, setUsers] = useState([{ id: 1 }]); 

  const addUser = () => {
    setUsers([...users, { id: users.length + 1 }]);
  };

  const removeUser = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };
  

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };
  

  return (
    <div className="add_new_company_form">
      <form>
        {/* Company Information Section */}
        <div className="form_section">
          <h2 className="section_title">Company Information</h2>

          <div className="form_grid">
            <div>
              <label htmlFor="companyName" className="form_label">Company Name</label>
              <input type="text" id="companyName" className="form_input" placeholder="Enter company name" />
            </div>

        
            <div className="grid_span_2">
      <label htmlFor="companyLogo" className="form_label">Company Logo</label>
      <div
        className="file_upload"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <UploadIcon
          style={{
            display: "block",
            justifyContent: "center",
            margin: "0 auto",
          }}
        />
        <span>
          Drag &amp; drop files or{" "}
          <label htmlFor="fileInput" className="file_upload_browse">
            Browse
          </label>
        </span>
        <input
          type="file"
          id="fileInput"
          className="file_input"
          accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff,.svg"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <p className="file_upload_note">
          Supported formats: .jpg, .jpeg, .png, .gif, .bmp, .tiff, .svg
        </p>
        {selectedFile && (
          <p className="file_selected">Selected File: {selectedFile.name}</p>
        )}
      </div>
    </div>
            <div>
              <label htmlFor="websiteURL" className="form_label">Website URL</label>
              <input type="text" id="websiteURL" className="form_input" placeholder="Enter website URL" />
            </div>

            <div>
              <label htmlFor="companySize" className="form_label">Company Size</label>
              <select id="companySize" className="form_select">
                <option>Select size</option>
                <option>Small</option>
                <option>Medium</option>
                <option>Large</option>
              </select>
            </div>

            <div>
              <label htmlFor="location" className="form_label">Location</label>
              <input type="text" id="location" className="form_input" placeholder="Enter location" />
            </div>

            <div>
              <label htmlFor="industry" className="form_label">Industry</label>
              <select id="industry" className="form_select">
                <option>Select industry</option>
                <option>Technology</option>
                <option>Healthcare</option>
                <option>Education</option>
              </select>
            </div>

            <div>
              <label htmlFor="manager" className="form_label">7 Eagle Manager</label>
              <select id="manager" className="form_select">
                <option>Jordie Kern</option>
                <option>Other Manager</option>
              </select>
            </div>
          </div>
        </div>

        {/* Users Section */}
        <div className="form_section">
  <h2 className="section_title" style={{marginBottom:"0px"}}>Users</h2>

  {users.map((user) => (

<div style={{position:"relative"}}><div> <p style={{    marginTop:"30px",
    fontSize: "13px"}}>User {user.id}</p></div>
  <div key={user.id} className="form_grid users-form">
 

    {/* First Name and Last Name in the same row */}
 
      <div>
        <label htmlFor={`firstName-${user.id}`} className="form_label">First Name</label>
        <input type="text" id={`firstName-${user.id}`} className="form_input" placeholder="Enter first name" />
      </div>
      <div>
        <label htmlFor={`lastName-${user.id}`} className="form_label">Last Name</label>
        <input type="text" id={`lastName-${user.id}`} className="form_input" placeholder="Enter last name" />
      </div>


    {/* Email Field */}
    <div>
      <label htmlFor={`email-${user.id}`} className="form_label">Email</label>
      <input type="email" id={`email-${user.id}`} className="form_input" placeholder="Enter email" />
    </div>

    {/* Role Field */}
    <div>
      <label htmlFor={`role-${user.id}`} className="form_label">Role</label>
      <select id={`role-${user.id}`} className="form_select">
        <option>Select role</option>
        <option>Admin</option>
        <option>Editor</option>
      </select>
    </div>

    {/* Close Button */}
    <div className="grid_span_2 close_btn_users">
      <button type="button" className="remove_user_button" onClick={() => removeUser(user.id)}>
        Close
      </button>
    </div>
  </div></div>
))}


  {/* Add User Button */}
  <div className="grid_span_2 add_user_button_container">
    <button type="button" className="add_user_button" onClick={addUser}>
      + Add User
    </button>
  </div>
</div>


        {/* Subscription Section */}
        <div className="form_section">
          <h2 className="section_title">Subscription</h2>

          <div>
            <label htmlFor="plan" className="form_label">Choose a Plan</label>
            <select id="plan" className="form_select" style={{width:"50%"}}>
              <option>Basic Plan</option>
              <option>Premium Plan</option>
            </select>
          </div>
        </div>

        {/* Submit Button */}
     
      </form>

      <div className="submit_footer_fixed">
          <button type="submit" className="submit_button">Create New Company</button>
        </div>
    </div>
  );
}

export default AddNewCompanyForm;
