// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-field{
    padding-top: 10px;
}

.form-field label{
    font-size: 13px;
}

.form-field textarea{
    min-height: 70px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.form-field input{
    padding: 10px 10px;
}
.btn-saved{
    display: flex;
    justify-content: flex-end;
}

.btn-saved button{
    border-radius: 10px ;
}
`, "",{"version":3,"sources":["webpack://./src/components/Companies/AboutCompany/EditAboutCompany.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".form-field{\n    padding-top: 10px;\n}\n\n.form-field label{\n    font-size: 13px;\n}\n\n.form-field textarea{\n    min-height: 70px;\n    padding: 10px;\n    border-radius: 10px;\n    margin-bottom: 10px;\n}\n\n.form-field input{\n    padding: 10px 10px;\n}\n.btn-saved{\n    display: flex;\n    justify-content: flex-end;\n}\n\n.btn-saved button{\n    border-radius: 10px ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
