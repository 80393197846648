import React, { useContext, useRef, useState } from "react";
import "./EditAboutProfileInfo.css";
import { toast, ToastContainer } from "react-toastify"; // For displaying success/error messages

import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
function CompleteProfileAbout({ candidate }) {
  const [formData, setFormData] = useState({
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    candidateType: candidate.type || "",
    state: candidate.state || "",
    city: candidate.city || "",
    branch: candidate.branch || "",
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    idealJobTitle: candidate.idealJobTitle || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    idno: candidate.recruiterFlowId || "", // Recruiterflow Id
    email: candidate.email || "",
    phone: candidate.phone || "",
    certifications: candidate.certifications || "",
    Pursuingcertifications: candidate.Pursuingcertifications || "",
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    targetIndustries: candidate.targetIndustries || "",
    yearsServed: candidate.yearsServed || "",
  });
  const [duringquestion, setDuringQuetion] = useState();
  const securityClearances = ["Top Secret", "Secret", "Confidential", "None"];
  const yearOfExperiences = [
    "Less than 1 year",
    "1-3 years",
    "3-5 years",
    "5-10 years",
    "10+ years",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `http://localhost:5000/api/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData), // Pass the complete form data
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  const inputref = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handleOnPlacesChangedDuringSkillBridge = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the formData state
        setFormData((prevData) => ({
          ...prevData,
          duringSkillbridgeCity: cityName,
          duringSkillbridgeState: stateName,
        }));

        console.log("During SkillBridgeCity:", cityName);
        console.log("During SkillBridgeState:", stateName);
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };

  const handleAfterSkillbridgeQuestion = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const skillbridgeQuestions = [
    "Yes - I know exactly where I'll live.",
    "Almost - I have a few options I'm considering.",
    "No - but I'm willing to relocate for the job.",
  ];
  const handleDuringSkillbridgeQuestion = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setDuringQuetion(e.target);
    console.log(duringquestion);
  };
  const handleOnPlacesChangedAfterSkillBridge = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the formData state
        setFormData((prevData) => ({
          ...prevData,
          afterskillbridgeCity: cityName,
          afterskillbridgeState: stateName,
        }));

        console.log("During SkillBridgeCity:", cityName);
        console.log("During SkillBridgeState:", stateName);
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };
  return (
    <div className="popup-content complete-profile-form">
      <ToastContainer />
      <div className="popup-header">
        <h2>Edit Your Profile Information</h2>
        <p>
          Give employers a better sense of who you are and what you're looking
          for. Highlight your background, ideal job, and availability to stand
          out!
        </p>
      </div>
      <hr />

      <div className="popup-form about-form">
        <div className="form-field">
          <label>
            Tell companies about yourself and your career goals.
            <span>*</span>
          </label>
          <textarea
            placeholder="Type Here"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className={!formData.description ? "error-border" : ""}
          />
        </div>

        {/* Security Clearance - Years of Experience */}
        <div className="two-fields">
          <div className="form-field">
            <label>
              Security Clearance <span>*</span>
            </label>
            <select
              value={formData.securityClearance}
              onChange={handleChange}
              name="securityClearance"
            >
              <option value="">Select Option</option>
              {securityClearances.map((loc, index) => (
                <option key={index} value={loc}>
                  {loc}
                </option>
              ))}
            </select>
          </div>

          <div className="form-field">
            <label>
              Years of Experience <span>*</span>
            </label>
            <select
              value={formData.yearsOfExperience}
              onChange={handleChange}
              name="yearsOfExperience"
            >
              <option value="">Select Option</option>
              {yearOfExperiences.map((exp, index) => (
                <option key={index} value={exp}>
                  {exp}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-field">
          <label>
            Ideal Job <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Type Here"
            name="idealJobTitle"
            value={formData.idealJobTitle}
            onChange={handleChange}
          />
        </div>

        {/* Check if candidate type is not 'Veteran' */}
        {candidate.type !== "Veteran" && (
          <>
            {/* SkillBridge Start Date - Skillbridge End date */}
            <div className="two-fields">
              <div className="form-field">
                <label>
                  Skillbridge Start Date <span>*</span>
                </label>
                <input
                  type="date"
                  name="skillbridgeStartDate"
                  value={formData.skillbridgeStartDate}
                  onChange={handleChange}
                />
              </div>

              <div className="form-field">
                <label>
                  Skillbridge End Date <span>*</span>
                </label>
                <input
                  type="date"
                  name="skillbridgeEndDate"
                  value={formData.skillbridgeEndDate}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* During SkillBridge */}
            <div className="form-field">
              <label>
                Do you know where you will live DURING SkillBridge?{" "}
                <span>*</span>
              </label>
              <select
                value={formData.locationDuringSkillbridge}
                onChange={handleDuringSkillbridgeQuestion}
                name="locationDuringSkillbridge"
              >
                <option value="">Select Option</option>
                {skillbridgeQuestions.map((exp, index) => (
                  <option key={index} value={exp}>
                    {exp}
                  </option>
                ))}
              </select>
            </div>
            {(formData.locationDuringSkillbridge ===
              "Yes - I know exactly where I'll live." ||
              formData.locationDuringSkillbridge ===
                "Almost - I have a few options I'm considering.") && (
              <div className="duringskillbridgecondition">
                {/* During SkillBridge */}

                <div className="form-field" style={{ marginBottom: "15px" }}>
                  {isLoaded && (
                    <div>
                      <label>
                        Location During Skillbrdige <span>*</span>
                      </label>
                      <StandaloneSearchBox
                        onLoad={(ref) => (inputref.current = ref)}
                        onPlacesChanged={handleOnPlacesChangedDuringSkillBridge}
                        style={{
                          zIndex: "10000",
                          position: "absolute",
                          top: "0px",
                          width: "100%",
                        }} // Higher z-index for the search box
                      >
                        <input
                          type="text"
                          placeholder="Search location"
                          style={{
                            width: "100%",
                            padding: "14px",
                            borderRadius: "10px",
                            border: "1px solid #ddddde",
                          }}
                        />
                      </StandaloneSearchBox>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="form-field">
              <label>
                Do you know where you will live After SkillBridge?{" "}
                <span>*</span>
              </label>
              <select
                value={formData.locationAfterSkillbridge}
                onChange={handleAfterSkillbridgeQuestion}
                name="locationAfterSkillbridge"
              >
                <option value="">Select Option</option>
                {skillbridgeQuestions.map((exp, index) => (
                  <option key={index} value={exp}>
                    {exp}
                  </option>
                ))}
              </select>
            </div>

            {(formData.locationAfterSkillbridge ===
              "Yes - I know exactly where I'll live." ||
              formData.locationAfterSkillbridge ===
                "Almost - I have a few options I'm considering.") && (
              <div className="condition-afterskillbridge">
                {/* Location After Skillbridge */}
                <div className="form-field" style={{ marginBottom: "15px" }}>
                  {isLoaded && (
                    <div>
                      <label>
                        Location After Skillbrdige <span>*</span>
                      </label>
                      <StandaloneSearchBox
                        onLoad={(ref) => (inputref.current = ref)}
                        onPlacesChanged={handleOnPlacesChangedAfterSkillBridge}
                        style={{
                          zIndex: "10000",
                          position: "absolute",
                          top: "0px",
                          width: "100%",
                        }} // Higher z-index for the search box
                      >
                        <input
                          type="text"
                          placeholder="Search location"
                          style={{
                            width: "100%",
                            padding: "14px",
                            borderRadius: "10px",
                            border: "1px solid #ddddde",
                          }}
                        />
                      </StandaloneSearchBox>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {/* Relocation Preference */}
        <div className="form-field">
          <label>
            Are you open to relocating? <span>*</span>
          </label>
          <select
            name="relocationprefrence"
            value={formData.relocationprefrence}
            onChange={handleChange}
          >
            <option value="">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Education */}
        <div className="form-field">
          <label>
            Education <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Type Here"
            name="education"
            value={formData.education}
            onChange={handleChange}
            className={!formData.education ? "error-border" : ""}
          />
        </div>
      </div>

      <hr />
      <div className="form-btn">
        <button type="submit" className="update-btn" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
}

export default CompleteProfileAbout;
