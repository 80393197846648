import React, { useState } from "react";
import "./TargetIndustries.css";
import { ReactComponent as SettingIcon } from "../../../assets/images/setting.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";     
import SelectTargetIndustries from "./SelectTargetIndustries/SelectTargetIndustries";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";


function TargetIndustries({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate((prev) => ({
      ...prev,
      targetIndustries: updatedData.targetIndustries,
    }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="target_industries">
      <h4>Target Industries</h4>

      {!isModalOpen ? (
        <div className="target-industries-section">
          {updatedCandidate.targetIndustries?.length > 0 ? (
            <div className="exist-target-value">
              {!hideEditIcon && (
                <div className="editIcon" onClick={openModal}>
                        <EditIcon/>
                </div>
              )}
              <div className="target-list-options">
                {/* Handle array, string, or invalid value */}
                {Array.isArray(updatedCandidate.targetIndustries) ? (
                  updatedCandidate.targetIndustries.map((industry, index) => (
                    <span key={index} className="industry-tag">
                      {industry}
                    </span>
                  ))
                ) : typeof updatedCandidate.targetIndustries === "string" ? (
                  <span className="industry-tag">
                    {updatedCandidate.targetIndustries}
                  </span>
                ) : (
                  <span className="industry-tag">
                    No target industries available
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className="empty-state">
              <div className="plusIcon" onClick={openModal}>
              <PlusIcon/>
              </div>
              <SettingIcon/>
              <p>
                Let companies know the industries you're most interested in.
              </p>
              <button onClick={openModal}>Select Target Industries</button>
            </div>
          )}
        </div>
      ) : (
        <SelectTargetIndustries
          isOpen={isModalOpen}
          onClose={closeModal}
          candidate={updatedCandidate}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
}

export default TargetIndustries;
