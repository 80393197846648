import React, { useState, useEffect, useRef } from "react";
import "./CandidateTypeFilter.css";


import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
 // Path to edit icon
 import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";
 // Path to delete icon

const CandidateTypeFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectCandidateType,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCandidateType, setSelectedCandidateType] = useState(null);
  const [filterType, setFilterType] = useState("is one of"); // Default filter type
  const [isConfirmed, setIsConfirmed] = useState(false); // To toggle between filter view and confirmation view
  const modalRef = useRef(null);

  // Filtering logic based on selected filter type
  const filteredCandidateTypes =
    searchTerm.length >= 2
      ? candidateData.filter((candidate) => {
          const candidateType = candidate.type?.toLowerCase() || "";
          const searchWords = searchTerm.trim().toLowerCase().split(/\s+/);

          switch (filterType) {
            case "is one of":
              return searchWords.every((word) => candidateType.includes(word));
            case "is not one of":
              return searchWords.some((word) => !candidateType.includes(word));
            case "is set":
              return candidateType.length > 0;
            case "is not set":
              return candidateType.length === 0;
            default:
              return false;
          }
        })
      : []; // Return empty list if searchTerm is less than 2 characters

  useEffect(() => {
    const handleOutsideClick = (event) => {};

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleSelectCandidateType = (type) => {
    setSelectedCandidateType(type);
    setSearchTerm(type); // Set the search term to selected candidate type
  };

  const handleDone = () => {
    if (selectedCandidateType) {
      onSelectCandidateType(selectedCandidateType); // Pass selected candidate type to parent
      setIsConfirmed(true); // Switch to confirmation view
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false); // Switch back to filter-options view
  };

  const handleDelete = () => {
    setSelectedCandidateType(null); // Clear selected candidate type
    setSearchTerm(""); // Reset search term
    setIsConfirmed(false); // Switch back to filter-options view
  };

  if (!isOpen) return null;

  return (
    <div className="candidate-type-modal-main">
      <div className="candidate-type-modal-content" ref={modalRef}>
        {isConfirmed ? (
          // Confirmation view after clicking Done
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                    <IconUser/>
                  Candidate Type
                </h6>
              </div>
              <div className="manage-icons">
              <EditIcon/>
            <DeleteIcon/>
              </div>
            </div>
            <p className="selected-candidate-type-name">
              {selectedCandidateType}
            </p>
          </div>
        ) : (
          // Default filter-options view
          <>
            <div className="filter-options">
              <h6>
                  <IconUser/>
                Candidate Type
              </h6>
              <div className="candidate-type-list-radio-buttons">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={filterType === "is one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={filterType === "is not one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is not one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={filterType === "is set"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is set
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={filterType === "is not set"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is not set
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Candidate Type"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="candidate-type-search-bar"
            />
            <div className="candidate-type-list">
              {searchTerm.length >= 2 && filteredCandidateTypes.length === 0 ? (
                <div className="no-results">No Match Result</div>
              ) : (
                filteredCandidateTypes.map((candidate, index) => (
                  <div
                    key={index}
                    className="candidate-type-item"
                    onClick={() => handleSelectCandidateType(candidate.type)}
                  >
                    {candidate.name}
                  </div>
                ))
              )}
            </div>

            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CandidateTypeFilter;
