import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext"; // Import AuthProvider
import PrivateRoute from "./components/PrivateRoute"; // Import PrivateRoute
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AdminCandidateDashboard from "./pages/Candidates/Candidates";
import CandidateCompleteProfile from "./pages/Candidates/CandidateCompleteProfile";
import CandidateProfilePage from "./pages/Candidates/CandidateProfilePage";
import "react-toastify/dist/ReactToastify.css";
import Courses from "./pages/Courses/Course-Page";
import CourseDetail from "./pages/Courses/CourseDetail";
import CandidatePublicProfile from "./pages/Candidates/CandidatePublicProfile";
import ClientRecords from "./pages/Company/Companies";
import AddNewCandidatesPage from "./pages/Candidates/AddNewCandidates";
import AdminDashboard from "./pages/AdminDashboard";
import AdminDashboardPage from "./pages/AdminDashboard";
import AddNewCompanyPage from "./pages/Company/AddNewCompanyPages/AddNewCompanyPage";
// import JobListsPage from "./pages/Jobs/JobLists";
function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route
            path="/admin/candidates"
            element={
              <PrivateRoute>
                <AdminCandidateDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/companies"
            element={
              <PrivateRoute>
                <ClientRecords />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/admin/profile-page"
            element={
              <PrivateRoute>
                <CandidateProfilePage />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/admin/courses"
            element={
              <PrivateRoute>
                <Courses />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/course/:id"
            element={
              <PrivateRoute>
                <CourseDetail />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/admin/profile/:userId"
            element={
              <PrivateRoute>
                <CandidatePublicProfile />
              </PrivateRoute>
            }
          /> */}

          <Route
            path="/admin/create-new-candidates"
            element={
              <PrivateRoute>
                <AddNewCandidatesPage />
              </PrivateRoute>
            }
          />

          {/* <Route
            path="/admin/jobs"
            element={
              <PrivateRoute>
                <JobListsPage />
              </PrivateRoute>
            }
          /> */}

          <Route
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                <AdminDashboardPage/>
              </PrivateRoute>
            }
          />


<Route
            path="/admin/create-new-company"
            element={
              <PrivateRoute>
                <AddNewCompanyPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
