import React, { useState, useEffect } from "react";
import Header from "../../components/Common/Header/Header";
import Sidebar from "../../components/Common/Sidebar/Sidebar";

import axios from "axios";

import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import "../AdminDashboard.css";
import CourseList from "../../components/courses/CourseList";

const Courses = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rfCandidateData, setRfCandidateData] = useState(null);

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  // Fetch candidate data (without authentication logic)
  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const userId = "67099545f6fe8bcd6e2543d3"; // Example userId
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/candidates/user/${userId}`
        );

        setCandidate(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(
          "Error During fatch Candidate:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchCandidate();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
    <Logo style={{width:"150px"}}/>
        <div className="spinner"></div>

        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Header candidate={candidate} />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar
            onToggleSidebar={handleToggleSidebar}
            candidate={candidate}
          />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            <div className="welcome-text">
              <h4 style={{ color: "black" }}>Browse Our Free Courses </h4>
              <p>
                Explore our collection of career development courses designed to
                help you sharpen your skills and stand out to potential
                employers.
              </p>

              <CourseList />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Courses;
