import React, { useState } from "react";
import "./AddNewCandidateVideoIntro.css";
import { ReactComponent as UploadIcon } from "../../../assets/images/uploadIcon.svg";
import videoIcon from "../../../assets/images/file.png";

function AddNewCandidateVideoIntro({ candidate, onClose, onVideoSelect }) {
  const [video, setVideo] = useState(null); // State for the video file
  const [error, setError] = useState(""); // State for error handling

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "video/mp4") {
      setError("");
      setVideo(selectedFile); // Store the video file in the state
      onVideoSelect(selectedFile); // Pass video to the parent component immediately
    } else {
      setError("You can’t upload this file. Only .mp4 format is allowed.");
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === "video/mp4") {
      setError("");
      setVideo(droppedFile); // Store the video file in the state
      onVideoSelect(droppedFile); // Pass video to the parent component immediately
    } else {
      setError("You can’t upload this file. Only .mp4 format is allowed.");
    }
  };

  return (
    <>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "600",
          marginBottom: "5px",
          marginTop: "5px",
        }}
      >
        Video Intro
      </p>
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-6">
          <div
            className="upload-box upload_new_candidate_video"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            {video ? (
              <div className="video-preview">
                <img src={videoIcon} alt="Video Icon" className="videoIcon" />
                <p>{video.name}</p>
              </div>
            ) : (
              <>
              <UploadIcon/>
                <p
                  style={{
                    padding: "10px",
                    fontWeight: "600",
                    fontSize: "16px",
                    paddingBottom: "0px",
                    margin: "0px",
                  }}
                >
                  Drag & drop files or{" "}
                  <label
                    htmlFor="video-upload"
                    className="browse-link"
                    style={{ cursor: "pointer " }}
                  >
                    <b> Browse</b>
                  </label>
                </p>
                <p style={{ fontSize: "12px" }}>Supported format: .mp4</p>
              </>
            )}
            <input
              type="file"
              id="video-upload"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="col-lg-6"></div>
      </div>

      {error && <p className="error-message">{error}</p>}
    </>
  );
}

export default AddNewCandidateVideoIntro;
