import React, { useState } from "react";
import "./Certification.css";
// import certificationIcon from "../../../assets/images/certification.png";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";     
import AddCertificate from "./AddCertificate/AddCertificate";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";




function Certification({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate((prev) => ({
      ...prev,
      certifications: updatedData.certifications,
      pursuingCertifications: updatedData.pursuingCertifications,
    }));
  };

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true); // Show the AddCertificate modal
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false); // Close the AddCertificate modal
  };

  return (
    <div className="certification">
      <h4 className="main_certification_heading">Certifications</h4>

      {/* If the modal isn't open, show certification list */}
      {!isModalOpen && (
        <div className="certificate-sections-list">
          {updatedCandidate.certifications?.length > 0 ||
          updatedCandidate.pursuingCertifications?.length > 0 ? (
            <div className="exist-target-value">
              {!hideEditIcon && (
                <div className="editIcon" onClick={openModal}>
                  <EditIcon/>
                </div>
              )}
              {/* <div className="certification-list-options">
                <span className="industry-tag">
                  {updatedCandidate.certifications?.join(", ")}
                </span>
              </div>

              <div className="certification-list-options">
                <span className="industry-tag">
                  {updatedCandidate.pursuingCertifications
                    ?.map((industry) => `${industry} (In-Progress)`)
                    .join(", ")}
                </span>
              </div> */}

{/* <div className="certification-list-options">
  <span className="industry-tag">
    {Array.isArray(updatedCandidate.certifications)
      ? updatedCandidate.certifications.join(", ")
      : "No certifications available"}
  </span>
</div>

<div className="certification-list-options">
  <span className="industry-tag">
    {Array.isArray(updatedCandidate.pursuingCertifications)
      ? updatedCandidate.pursuingCertifications
          .map((industry) => `${industry} (In-Progress)`)
          .join(", ")
      : "No pursuing certifications available"}
  </span>
</div> */}

<div className="certification-list-options">
  <span className="industry-tag">
    {Array.isArray(updatedCandidate.certifications)
      ? updatedCandidate.certifications.length > 0
        ? updatedCandidate.certifications.join(", ")
        : "No certifications added yet" // Empty array fallback
      : typeof updatedCandidate.certifications === "string"
      ? updatedCandidate.certifications // Directly show string
      : "No certifications available"} // Default fallback
  </span>
</div>

<div className="certification-list-options">
  <span className="industry-tag">
    {Array.isArray(updatedCandidate.pursuingCertifications)
      ? updatedCandidate.pursuingCertifications.length > 0
        ? updatedCandidate.pursuingCertifications
            .map((industry) => `${industry} (In-Progress)`)
            .join(", ")
        : "No pursuing certifications added yet" // Empty array fallback
      : typeof updatedCandidate.pursuingCertifications === "string"
      ? updatedCandidate.pursuingCertifications // Directly show string
      : "No pursuing certifications available"} // Default fallback
  </span>
</div>

            </div>
          ) : (
            <div className="empty-state">
              <div className="plusIcon" onClick={openModal}>
              <PlusIcon/>
              </div>
              {/* <img src={certificationIcon} alt="certification" /> */}
              <p>
                Highlight your skills and qualifications by adding any relevant
                certifications. Stand out to companies
                <br /> looking for your expertise.
              </p>
              <button onClick={openModal}>Add Certifications</button>
            </div>
          )}
        </div>
      )}

      {/* If the modal is open, show AddCertificate component */}
      {isModalOpen && (
        <AddCertificate
          isOpen={isModalOpen}
          onClose={closeModal}
          candidate={updatedCandidate}
          onUpdate={handleUpdate} // Send handleUpdate to the child
        />
      )}
    </div>
  );
}

export default Certification;
