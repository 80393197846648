import React, { useState, useEffect, useRef } from "react";
import "./MilitaryBranchFilter.css";
import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
 // Path to edit icon
 import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";
 // Path to delete icon

const MilitaryBranchFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectBranch,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filterType, setFilterType] = useState("is one of"); // Default filter type
  const [isConfirmed, setIsConfirmed] = useState(false); // To toggle between filter view and confirmation view
  const modalRef = useRef(null);

  // Filtering logic based on selected filter type
  const filteredBranches =
    searchTerm.length >= 2
      ? candidateData.filter((candidate) => {
          const branch = candidate.branch?.toLowerCase() || "";
          const searchWords = searchTerm.trim().toLowerCase().split(/\s+/);

          switch (filterType) {
            case "is one of":
              return searchWords.every((word) => branch.includes(word));
            case "is not one of":
              return searchWords.some((word) => !branch.includes(word));
            case "is set":
              return branch.length > 0;
            case "is not set":
              return branch.length === 0;
            default:
              return false;
          }
        })
      : []; // Return empty list if searchTerm is less than 2 characters

  useEffect(() => {
    const handleOutsideClick = (event) => {};

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleSelectBranch = (branch) => {
    setSelectedBranch(branch);
    setSearchTerm(branch); // Set the search term to the selected branch
  };

  const handleDone = () => {
    if (selectedBranch) {
      onSelectBranch(selectedBranch); // Pass selected branch to parent
      setIsConfirmed(true); // Switch to confirmation view
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false); // Switch back to filter-options view
  };

  const handleDelete = () => {
    setSelectedBranch(null); // Clear selected branch
    setSearchTerm(""); // Reset search term
    setIsConfirmed(false); // Switch back to filter-options view
  };

  if (!isOpen) return null;

  return (
    <div className="military-branch-modal-main">
      <div className="military-branch-modal-content" ref={modalRef}>
        {isConfirmed ? (
          // Confirmation view after clicking Done
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                    <IconUser/>
                  Military Branch
                </h6>
              </div>
              <div className="manage-icons">
              <EditIcon/>
            <DeleteIcon/>
              </div>
            </div>
            <p className="selected-branch-name">{selectedBranch}</p>
          </div>
        ) : (
          // Default filter-options view
          <>
            <div className="filter-options">
              <h6>
                  <IconUser/>
                Military Branch
              </h6>
              <div className="branch-list-radio-buttons">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={filterType === "is one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={filterType === "is not one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is not one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={filterType === "is set"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is set
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={filterType === "is not set"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is not set
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Military Branch"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="branch-search-bar"
            />
            <div className="branch-list">
              {searchTerm.length >= 2 && filteredBranches.length === 0 ? (
                <div className="no-results">No Match Result</div>
              ) : (
                filteredBranches.map((candidate, index) => (
                  <div
                    key={index}
                    className="branch-item"
                    onClick={() => handleSelectBranch(candidate.branch)}
                  >
                    {candidate.branch}
                  </div>
                ))
              )}
            </div>

            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MilitaryBranchFilter;
