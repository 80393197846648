import React, { useState,useEffect, useRef } from "react";
import axios from "axios";
import "./AddNewCandidateForm.css";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import AddNewCandidatePhoto from "./AddNewCandidatePhoto";
import AddNewCandidateResume from "./AddNewCandidateResume";
import AddNewCandidateVideoIntro from "./AddNewCandidateVideoIntro";

const AddCandidateForm = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jobStatuses, setJobStatuses] = useState([]);
  const [rank, setRank] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [values,setValues] = useState([]);
  const [targetIndustriesList,setTargetIndustriesList] =  useState([]);
const [jobTitlesList,setJobTitlesList] =  useState([]);
const [certificationList,setCertificationList] =  useState([]);
const [mosList,setMosList] =  useState([]);

const [targetIndustry, setTargetIndustry] = useState('');  // Full list of industries
const [filteredIndustries, setFilteredIndustries] = useState([]); // Filtered list based on search
const [selectedTargetIndustries, setSelectedTargetIndustries] = useState([]); // Selected industries (tags)
const [isCreatingIndustry, setIsCreatingIndustry] = useState(false);


  const [candidateData, setCandidateData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    idno: 12345678,
    city: "",
    state: "",
    candidateType: "",
    rank: "",
    mos: "",
    branch: "",
    yearsServed: "",
    targetIndustries: [],
    certifications: "",
    Pursuingcertifications: "",
    description: "",
    securityClearance: "",
    yearsOfExperience: "",
    skillbridgeStartDate: "",
    skillbridgeEndDate: "",
    idealJobTitle: "",
    duringSkillbridgeCity: "",
    duringSkillbridgeState: "",
    afterskillbridgeCity: "",
    afterskillbridgeState: "",
    relocationprefrence: "",
    education: "",
    jobSearchStatus: "",
    jobSearchStatusName: "",
    locationDuringSkillbridge: "",
    managerName: "",
    locationAfterSkillbridge: "",
    headshotUrl: "",
  });

  // Ideal Job Title

  const [idealJobTitle, setIdealJobTitle] = useState("");
  const [idealJobSuggestions, setIdealJobSuggestions] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState(
    Array.isArray(candidateData.idealJobTitle)
      ? candidateData.idealJobTitle
      : []
  );

  

  // Ideal Job Title Function

  const handleJobTitleInput = (e) => {
    const input = e.target.value;
    setIdealJobTitle(input);

    if (input.length > 0) {
      const filteredSuggestions = jobTitlesList.filter((title) =>
        title.toLowerCase().includes(input.toLowerCase())
      );

      // Add "Create new:" suggestion if not in the list
      if (!filteredSuggestions.includes(input)) {
        filteredSuggestions.push(`Create new: ${input}`);
      }

      setIdealJobSuggestions(filteredSuggestions);
    } else {
      setIdealJobSuggestions([]);
    }
  };

  const handleSuggestionClick = (title) => {
    if (title.startsWith("Create new: ")) {
      const newTitle = title.replace("Create new: ", "");
      addJobTitle(newTitle);
    } else {
      addJobTitle(title);
    }
    setIdealJobTitle("");
    setIdealJobSuggestions([]);
  };

  const addJobTitle = (title) => {
    if (!selectedJobTitles.includes(title)) {
      setSelectedJobTitles((prev) => [...prev, title]);
    }
  };

  const removeJobTitle = (index) => {
    const updatedTitles = selectedJobTitles.filter((_, i) => i !== index);
    setSelectedJobTitles(updatedTitles);
  };

  // Certification Start state

  const [certification, setCertification] = useState("");
  const [certificationSuggestions, setCertificationSuggestions] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState(
    Array.isArray(candidateData.certifications)
      ? candidateData.certifications
      : []
  );

  

  // Certification Function

  const handleCertificationInput = (e) => {
    const input = e.target.value;
    setCertification(input);

    if (input.length > 0) {
      const filteredSuggestions = certificationList.filter((title) =>
        title.toLowerCase().includes(input.toLowerCase())
      );

      // Add "Create new:" suggestion if not in the list
      if (!filteredSuggestions.includes(input)) {
        filteredSuggestions.push(`Create new: ${input}`);
      }

      setCertificationSuggestions(filteredSuggestions);
    } else {
      setCertificationSuggestions([]);
    }
  };

  const handleCertificationSuggestionClick = (title) => {
    if (title.startsWith("Create new: ")) {
      const newCertification = title.replace("Create new: ", "");
      addCertification(newCertification);
    } else {
      addCertification(title);
    }
    setCertification("");
    setCertificationSuggestions([]);
  };

  const addCertification = (title) => {
    if (!selectedCertifications.includes(title)) {
      setSelectedCertifications((prev) => [...prev, title]);
    }
  };

  const removeCertification = (index) => {
    const updatedCertifications = selectedCertifications.filter(
      (_, i) => i !== index
    );
    setSelectedCertifications(updatedCertifications);
  };

  // Target ranks
// Fetch ranks from the server
const fetchRanks = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/admin/rank/rank`);
    setRank(response.data); // Set the list of ranks
    setFilteredRanks(response.data); // Initially show all ranks in the filtered list
  } catch (error) {
    console.error('Error fetching ranks:', error);
  }
};

// Handle changes in the rank dropdown
const handleRankChange = (e) => {
  const { value } = e.target;
  setCandidateData((prevData) => ({
    ...prevData,
    rank: value,
  }));
};

// Handle input change when user types in the rank field
const [filteredRanks, setFilteredRanks] = useState([]); // List of ranks filtered by user input
const [newRank, setNewRank] = useState(''); // User input for custom rank
const [isOtherSelected, setIsOtherSelected] = useState(false); 

const handleRankInput = (e) => {
  const value = e.target.value;
  setNewRank(value);

  if (value.trim() !== '') {
    const filteredRanks = rank.filter((rankObj) =>
      rankObj.rank.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredRanks(filteredRanks); // Update filtered ranks based on user input
  } else {
    setFilteredRanks(rank); // Reset to the full list if input is empty
  }
};

// Handle selection of a rank from the dropdown list
const handleRankSelection = (rankObj) => {
  setCandidateData({
    ...candidateData,
    rank: rankObj.rank,
  });

  setNewRank(''); // Clear the input field
  setFilteredRanks(rank); // Reset the filtered ranks list to show all options
};

// Handle creating a new rank if the user input is not in the list
const handleCreateNewRank = async () => {
  if (newRank.trim() === '') return; // Do not proceed if the input is empty

  try {
    const response = await axios.post(`${apiUrl}/api/admin/rank/rank`, {
      rank: newRank,
    });

    // After creating the rank, add it to the list and select it
    setRank([...rank, response.data]); // Add the new rank to the full list
    setCandidateData({
      ...candidateData,
      rank: response.data.rank, // Automatically select the newly created rank
    });

    // Reset the input field and filtered list
    setNewRank('');
    setFilteredRanks([...rank, response.data]);
    setIsOtherSelected(false); 
  } catch (error) {
    console.error('Error creating new rank:', error);
  }
};


 

  // Predefined list of target industries (can come from an API or be hardcoded)
  

  // Target Industries Function
  const fetchIndustries = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/industry/industry`); // Replace with your API endpoint
      setTargetIndustriesList(response.data);
      setFilteredIndustries(response.data); // Set filtered industries to full list initially
    } catch (error) {
      console.error("Error fetching industries", error);
    }
  };

  const handleTargetIndustryInput = (e) => {
    const value = e.target.value;
    setTargetIndustry(value);

    // If the input field is not empty, filter the list based on user input
    if (value.trim() !== '') {
      const filteredIndustries = targetIndustriesList.filter((industryObj) => {
        const industry = industryObj.industry;

        // Ensure that industry is a valid string
        if (typeof industry !== 'string') {
          console.warn("Invalid industry found:", industryObj);
          return false;
        }

        // Perform case-insensitive search
        return industry.toLowerCase().includes(value.toLowerCase());
      });

      setFilteredIndustries(filteredIndustries); // Update the filtered list
    } else {
      // Reset the filtered list to the full list if input is empty
      setFilteredIndustries(targetIndustriesList);
    }
  };

  const handleTargetIndustrySuggestionClick = (industryObj) => {
    // Add the industry to selectedTargetIndustries (if not already selected)
    if (!selectedTargetIndustries.includes(industryObj.industry)) {
      setSelectedTargetIndustries([...selectedTargetIndustries, industryObj.industry]);

      // Remove selected industry from filtered list
      setFilteredIndustries(filteredIndustries.filter(industry => industry._id !== industryObj._id));
      setTargetIndustry(''); // Clear the input field after selection
    }
  };

  const handleCreateNewIndustry = async () => {
    if (targetIndustry.trim() === '') return;

    try {
      // Send a POST request to create a new industry
      const response = await axios.post(`${apiUrl}/api/admin/industry/industry`, {
        industry: targetIndustry,
      });

      // After creating the industry, update the list and select it
      setSelectedTargetIndustries([...selectedTargetIndustries, response.data.industry]);

      // Fetch industries again to include the newly created industry
      fetchIndustries();
      setTargetIndustry(''); // Clear the input field
    } catch (error) {
      console.error("Error creating new industry", error);
    }
  };

  const removeTargetIndustry = (index) => {
    // Remove the selected industry by index
    const removedIndustry = selectedTargetIndustries[index];
    const newSelectedIndustries = selectedTargetIndustries.filter((_, i) => i !== index);

    // Re-add the removed industry back to the available industries list
    const removedIndustryObj = targetIndustriesList.find(industryObj => industryObj.industry === removedIndustry);
    setFilteredIndustries([...filteredIndustries, removedIndustryObj]);

    // Update selected industries
    setSelectedTargetIndustries(newSelectedIndustries);
  };

  // Check if the current input is already part of the available industries list
  const isIndustryInList = targetIndustriesList.some((industryObj) =>
    industryObj.industry.toLowerCase() === targetIndustry.toLowerCase()
  );

 

  
  

  

  const handleImageSelect = (image) => {
    setSelectedImage(image);

    // Update the candidateData state to include the selected image
    setCandidateData((prevData) => ({
      ...prevData,
      headshotUrl: image, // Assign the selected image to headshotUrl
    }));
  };
  const [errors, setErrors] = useState({});
  const handleOnPlacesChanged = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the candidateData state
        setCandidateData((prevData) => ({
          ...prevData,
          city: cityName,
          state: stateName,
        }));
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };

  const inputref = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Handle jobSearchStatus specifically
    if (name === "jobSearchStatus") {
      const numericValue = Number(value);  // Convert to number if needed
      const status = values.find((statusObj) => statusObj._id === numericValue); // Find the status object based on _id
  
      setCandidateData((prevData) => ({
        ...prevData,
        [name]: numericValue,  // Update the jobSearchStatus with the numeric value
        jobSearchStatusName: status ? status.status : '',  // Set jobSearchStatusName to the matching status
      }));
    } else {
      // For all other fields, just update the state normally
      setCandidateData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  


  useEffect(() => {
    // Function to fetch all required data when the component mounts
    const fetchData = async () => {
      setLoading(true);
    

      try {
        // Use Promise.all to fetch all data concurrently
        const [jobStatusResponse, targetIndustriesResponse,rankResponse, jobTitlesResponse, certificationsResponse, mosListResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/admin/jobstatus/jobstatus`),
          axios.get(`${apiUrl}/api/admin/industry/industry`),
          axios.get(`${apiUrl}/api/admin/rank/rank`),
          axios.get(`${apiUrl}/api/admin/jobtitle/jobtitle`),
          axios.get(`${apiUrl}/api/admin/certification/certification`),
          axios.get(`${apiUrl}/api/admin/moslist/moslist`)
        ]);

        // Set state after all responses are received
        setJobStatuses(jobStatusResponse.data);  // Update state with job statuses
        setValues(jobStatusResponse.data);
        setRank(rankResponse.data);
        setTargetIndustriesList(targetIndustriesResponse.data);  // Update state with target industries
        setJobTitlesList(jobTitlesResponse.data);  // Update state with job titles
        setCertificationList(certificationsResponse.data);  // Update state with certifications
        setMosList(mosListResponse.data);  // Update state with MOS list

        toast.success("Data fetched successfully!");

      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  // You can log state here for debugging
  console.log("Job Statuses:", jobStatuses);
  console.log("Target Industries List:", targetIndustriesList);
  console.log("ranks List:", rank);
  console.log("Job Titles List:", jobTitlesList);
  console.log("Certification List:", certificationList);
  console.log("MOS List:", mosList);

  console.log("jobStatuses new",jobStatuses)

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Required fields
    const requiredFields = [
      "fname",
      "lname",
      "email",
      "phone",
      "candidateType",
      "jobSearchStatusName",
      "rank",
      "mos",
      "branch",
      "yearsServed",
    ];

    // Check for missing fields
    const missingFields = requiredFields.filter(
      (field) => !candidateData[field]?.trim()
    );

    if (missingFields.length > 0) {
      toast.error(
        `Please fill in the required fields: ${missingFields.join(", ")}`,
        {
          autoClose: 5000,
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return; // Stop form submission
    }

    try {

      // Notify user of submission progress
      toast.info("Submitting candidate data, please wait...", {
        autoClose: 2000,
        position: "top-center",
      });

      const response = await axios.post(
        `${apiUrl}/api/admin/candidates/new-candidates`,
        {
          ...candidateData,
          idealJobTitles: selectedJobTitles,
          certifications: selectedCertifications,
          targetIndustries: selectedTargetIndustries,
        }
      );

      // Success notification
      toast.success("Candidate added successfully!");

      // Reset form data
      setCandidateData({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        idno: "",
        city: "",
        state: "",
        candidateType: "",
        rank: "",
        mos: "",
        branch: "",
        yearsServed: "",
        targetIndustries: "",
        certifications: "",
        Pursuingcertifications: "",
        description: "",
        securityClearance: "",
        yearsOfExperience: "",
        skillbridgeStartDate: "",
        skillbridgeEndDate: "",
        idealJobTitle: "",
        duringSkillbridgeCity: "",
        duringSkillbridgeState: "",
        afterskillbridgeCity: "",
        afterskillbridgeState: "",
        reprefrence: "",
        education: "",
        jobSearchStatus: "",
        jobSearchStatusName: "",
        DuringSkillbridge: "",
        AfterSkillbridge: "",
        headshotUrl: "",
        managerName: "",
      });
    } catch (error) {
      console.error("Error adding candidate:", error);

      // Show appropriate error message
      toast.error(
        error.response?.data?.message ||
          "Failed to add candidate. Please try again."
      );
    }
  };

  return (
    <div className="add-candidate-form">
      <div className="profilePhotoCandidate"></div>
      <form onSubmit={handleSubmit}>
        <AddNewCandidatePhoto onImageSelect={handleImageSelect} />
        {/* Personal Details */}
        <div className="form-row">
          <div className="form-group ">
            <label htmlFor="fname">First Name</label>
            <input
              type="text"
              id="fname"
              name="fname"
              placeholder="Jim"
              value={candidateData.fname}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lname">Last Name</label>
            <input
              type="text"
              id="lname"
              name="lname"
              placeholder="Smith"
              value={candidateData.lname}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="jimsmith@gmail.com"
              name="email"
              value={candidateData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="(325) 650 - 2006"
              value={candidateData.phone}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="candidateType">Candidate Type</label>
            <select
              id="candidateType"
              name="candidateType"
              value={candidateData.candidateType}
              onChange={handleChange}
            >
              <option value="">Select Candidate Type</option>
              <option value="Veteran">Veteran</option>
              <option value="Transitioning Service Member">
                Transitioning Service Member
              </option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="managerName">Manager Name</label>
            <input
              type="text"
              id="managerName"
              name="managerName"
              placeholder="Manager Name"
              value={candidateData.managerName}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Address add */}

        <div className="form-row">
          <div className="form-group">
            <div className="form-field">
              {isLoaded && (
                <div
                  style={{
                    marginBottom: "15px",
                    marginTop: "0px",
                    paddingTop: "0px",
                  }}
                >
                  <label>
                    Location <span>*</span>
                  </label>
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputref.current = ref)}
                    onPlacesChanged={handleOnPlacesChanged}
                    style={{
                      zIndex: "10000",
                      position: "absolute",
                      top: "0px",
                      width: "100%",
                    }} // Higher z-index for the search box
                  >
                    <input
                      type="text"
                      placeholder="Search Location"
                      className={!candidateData.city ? "error-borderx" : ""}
                      style={{
                        width: "100%",
                        padding: "14px",
                        borderRadius: "10px",
                        border: "1px solid #ddddde",
                      }}
                    />
                  </StandaloneSearchBox>
                  {errors.city && (
                    <span className="error-message-field">{errors.city}</span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="jobSearchStatus">Job Search Status working</label>
            <select
              id="jobSearchStatus"
              name="jobSearchStatus"
              value={candidateData.jobSearchStatus}
              onChange={handleChange}
            >
               {values.length > 0 &&
        values.map((statusObj, index) => (
          <option key={statusObj._id} value={statusObj._id}>
            {statusObj.status}  {/* Display the 'status' property */}
          </option>
        ))}
                {/* <option value="other">Other</option> Add the "Other" option */}
            </select>
          </div>

          <div className="form-group"></div>
        </div>

        {/* Upload Resume Component*/}

        <AddNewCandidateResume />

        {/* Upload Video Intro Component */}

        <AddNewCandidateVideoIntro />

        {/* Candidate Information */}

        <h6 className="sub_heading_add_new">Candidate Information</h6>

        <div className="form-row">
          <div className="form-group">
            <label>Short Bio</label>
            <textarea />
          </div>

          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="idealJobTitle">Ideal Job Title</label>
            <input
              type="text"
              id="idealJobTitle"
              name="idealJobTitle"
              placeholder="Type or search ideal job titles here"
              value={idealJobTitle}
              onChange={handleJobTitleInput}
            />
            {idealJobSuggestions.length > 0 && (
              <ul className="suggestions">
                {idealJobSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="suggestion-item"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
            <div className="job-title-tags">
              {selectedJobTitles.map((title, index) => (
                <span key={index} className="tag">
                  {title}
                  <button onClick={() => removeJobTitle(index)}>x</button>
                </span>
              ))}
            </div>
          </div>

          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="availableToStart">Available to Start</label>
            <input
              type="date"
              id="availableToStart"
              name="availableToStart"
              value={candidateData.skillbridgeStartDate}
              onChange={handleChange}
            />
          </div>

          <div className="form-group"></div>
        </div>

        {/* Location During SkillBridge */}
        <div className="form-row">
          <div className="form-group">
            <div className="form-field">
              {isLoaded && (
                <div
                  style={{
                    marginBottom: "15px",
                    marginTop: "0px",
                    paddingTop: "0px",
                  }}
                >
                  <label>Location During SkillBridge</label>
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputref.current = ref)}
                    onPlacesChanged={handleOnPlacesChanged}
                    style={{
                      zIndex: "10000",
                      position: "absolute",
                      top: "0px",
                      width: "100%",
                    }} // Higher z-index for the search box
                  >
                    <input
                      type="text"
                      placeholder="Search Location"
                      className={
                        !candidateData.locationDuringSkillbridge
                          ? "error-borderx"
                          : ""
                      }
                      style={{
                        width: "100%",
                        padding: "14px",
                        borderRadius: "10px",
                        border: "1px solid #ddddde",
                      }}
                    />
                  </StandaloneSearchBox>
                  {errors.city && (
                    <span className="error-message-field">
                      {errors.locationDuringSkillbridge}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="form-group"></div>
        </div>

        {/* Location After Skillbridge */}

        <div className="form-row">
          <div className="form-group">
            <div className="form-field">
              {isLoaded && (
                <div
                  style={{
                    marginBottom: "15px",
                    marginTop: "0px",
                    paddingTop: "0px",
                  }}
                >
                  <label>Location After SkillBridge</label>
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputref.current = ref)}
                    onPlacesChanged={handleOnPlacesChanged}
                    style={{
                      zIndex: "10000",
                      position: "absolute",
                      top: "0px",
                      width: "100%",
                    }} // Higher z-index for the search box
                  >
                    <input
                      type="text"
                      placeholder="Search Location"
                      className={
                        !candidateData.afterskillbridgeCity
                          ? "error-borderx"
                          : ""
                      }
                      style={{
                        width: "100%",
                        padding: "14px",
                        borderRadius: "10px",
                        border: "1px solid #ddddde",
                      }}
                    />
                  </StandaloneSearchBox>
                  {errors.city && (
                    <span className="error-message-field">
                      {errors.afterskillbridgeCity}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="education">Education</label>
            <input
              type="text"
              id="education"
              name="education"
              placeholder="Enter Value Here"
              value={candidateData.education}
              onChange={handleChange}
            />
          </div>

          <div className="form-group"></div>
        </div>

        <div className="form-row">
      <div className="form-group">
        <label htmlFor="targetIndustries">Target Industries</label>
        <input
          type="text"
          id="targetIndustries"
          name="targetIndustries"
          placeholder="Type or search target industries here"
          value={targetIndustry}
          onChange={handleTargetIndustryInput}
        />

        {/* Show suggestions if any */}
        {filteredIndustries.length > 0 && (
          <ul className="suggestions">
            {filteredIndustries.map((industryObj) => (
              <li
                key={industryObj._id}
                onClick={() => handleTargetIndustrySuggestionClick(industryObj)}
                className="suggestion-item"
              >
                {industryObj.industry}
              </li>
            ))}
          </ul>
        )}

        {/* If the text typed is not in the list, show a button to create the new industry */}
        {targetIndustry && !isIndustryInList && (
          <div className="create-industry">
            <button
              type="button"
              onClick={handleCreateNewIndustry}
              className="create-button"
            >
              Create "{targetIndustry}" Industry
            </button>
          </div>
        )}

        <div className="industry-tags">
          {selectedTargetIndustries.map((industry, index) => (
            <span key={index} className="tag">
              {industry}
              <button type="button" onClick={() => removeTargetIndustry(index)} className="remove-tag">
                x
              </button>
            </span>
          ))}
        </div>
      </div>
    </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="securityClearance">Security Clearance</label>
            <select
              id="securityClearance"
              name="securityClearance"
              value={candidateData.securityClearance}
              onChange={handleChange}
            >
              <option value="">Select Security Clearance</option>
              <option value="Confidential">Confidential</option>
              <option value="Secret">Secret</option>
              <option value="Top Secret">Top Secret</option>
              <option value="Top Secret/SCI">Top Secret/SCI</option>
              {/* Add more security clearance options as needed */}
            </select>
          </div>
          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="yearsOfExperience">Years of Experience</label>
            <select
              id="yearsOfExperience"
              name="yearsOfExperience"
              value={candidateData.yearsOfExperience}
              onChange={handleChange}
            >
              <option value="">Select Years of Experience</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10+</option>
              {/* Add more years of experience options as needed */}
            </select>
          </div>

          <div className="form-group"></div>
        </div>

        {/* Professional Details */}

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="certifications">Certifications</label>
            <input
              type="text"
              id="certifications"
              name="certifications"
              placeholder="Type or search certifications here"
              value={certification}
              onChange={handleCertificationInput}
            />
            {certificationSuggestions.length > 0 && (
              <ul className="suggestions">
                {certificationSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() =>
                      handleCertificationSuggestionClick(suggestion)
                    }
                    className="suggestion-item"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
            <div className="certification-tags">
              {selectedCertifications.map((certification, index) => (
                <span key={index} className="tag">
                  {certification}
                  <button onClick={() => removeCertification(index)}>x</button>
                </span>
              ))}
            </div>
          </div>
          <div className="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="branch">Branch</label>
            <select
              id="branch"
              name="branch"
              value={candidateData.branch}
              onChange={handleChange}
            >
              <option value="">Select Branch</option>
              <option value="Army">Army</option>
              <option value="Navy">Navy</option>
              <option value="Air Force">Air Force</option>
              <option value="Marines">Marines</option>
              <option value="Coast Guard">Coast Guard</option>
              {/* Add more branch options as needed */}
            </select>
          </div>

          <div className="form-group"></div>
        </div>
{/* ranks list */}
<div className="form-row">
      <div className="form-group">
        <label htmlFor="rank">Rank</label>
        <input
          type="text"
          id="rank"
          name="rank"
          placeholder="Type or select a rank"
          value={newRank}
          onChange={handleRankInput}
        />

        {/* Show the filtered ranks in a dropdown */}
        {filteredRanks.length > 0 && (
          <ul className="suggestions">
            {filteredRanks.map((rankObj) => (
              <li
                key={rankObj._id}
                onClick={() => handleRankSelection(rankObj)}
                className="suggestion-item"
              >
                {rankObj.rank}
              </li>
            ))}
          </ul>
        )}

        {/* Show the create new rank button if input is not in the list */}
        {newRank && !filteredRanks.some((rankObj) => rankObj.rank.toLowerCase() === newRank.toLowerCase()) && (
          <div className="create-rank">
            <button
              type="button"
              onClick={handleCreateNewRank}
              className="create-button"
            >
              Create "{newRank}" Rank
            </button>
          </div>
        )}
      </div>

      {/* Dropdown for selecting rank */}
      <div className="form-group">
        <label htmlFor="rank">Select Rank</label>
        <select
          id="rank"
          name="rank"
          value={candidateData.rank}
          onChange={handleRankChange}
        >
          <option value="">Select Rank</option>
          {rank.map((rankObj) => (
            <option key={rankObj._id} value={rankObj.rank}>
              {rankObj.rank}
            </option>
          ))}
          <option value="Other">Other</option>
        </select>
      </div>
    </div>

        {/* Mos */}

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="mos">MOS</label>
            <select
              id="mos"
              name="mos"
              value={candidateData.mos}
              onChange={handleChange}
            >
              <option value="">Select MOS</option>
              <option value="11B - Infantryman">11B - Infantryman</option>
              <option value="25B - Information Technology Specialist">
                25B - Information Technology Specialist
              </option>
              <option value="68W - Combat Medic Specialist">
                68W - Combat Medic Specialist
              </option>
              <option value="12B - Combat Engineer">
                12B - Combat Engineer
              </option>
              <option value="35F - Intelligence Analyst">
                35F - Intelligence Analyst
              </option>
              <option value="19K - M1 Armor Crewman">
                19K - M1 Armor Crewman
              </option>
              <option value="88M - Motor Transport Operator">
                88M - Motor Transport Operator
              </option>
              {/* Add more MOS options as needed */}
            </select>
          </div>

          <div class="form-group"></div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="yearsServed">Years Served</label>
            <select
              id="yearsServed"
              name="yearsServed"
              value={candidateData.yearsServed}
              onChange={handleChange}
            >
              <option value="">Select Years Served</option>
              <option value="1-3 years">1-3 years</option>
              <option value="4-6 years">4-6 years</option>
              <option value="7-10 years">7-10 years</option>
              <option value="11-15 years">11-15 years</option>
              <option value="16-20 years">16-20 years</option>
              <option value="20+ years">20+ years</option>
            </select>
          </div>

          <div className="form-group"></div>
        </div>

        {/* Additional Fields */}

        {/* Submit Button */}
        <button
          type="submit"
          className="btn btn-primary"
          style={{ background: "#ffc500" }}
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default AddCandidateForm;
