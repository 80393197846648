import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Common/Header/Header";
import Sidebar from "../../components/Common/Sidebar/Sidebar";
import axios from "axios";
import { ReactComponent as Logo } from "../../assets/images/logo-7eagle.svg";
import "../AdminDashboard.css";
import CourseBanner from "../../components/courses/CourseBanner";
import CourseChapterList from "../../components/courses/CourseChapterList";

const CourseDetail = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams(); // Get the course ID from the URL
  const navigate = useNavigate(); // Use for navigation

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  useEffect(() => {
    const fetchCourseDetail = async () => {
      try {
        const response = await fetch(`http://localhost:5000/api/courses/${id}`);
        if (!response.ok) {
          throw new Error("Course not found");
        }
        const data = await response.json();
        setCourse(data);
      } catch (err) {
        console.error("Error fetching course details:", err);
        setError(err.message);
      }
    };

    fetchCourseDetail();
  }, [id]);

  // Fetch candidate data (without authentication logic)
  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const userId = "67099545f6fe8bcd6e2543d3"; // Example userId
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/candidates/user/${userId}`
        );
        setCandidate(response.data.data);
      } catch (error) {
        console.error(
          "Error fetching candidate:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCandidate();
  }, []);

  useEffect(() => {
    if (error) {
      // Redirect to course list page if there is an error (e.g., course not found)
      navigate("/admin/courses");
    }
  }, [error, navigate]);

  if (loading) {
    return (
      <div className="loading-container">
        {/* <img src={logo} className="logo-pre-loader" alt="Loading..." /> */}
        <Logo/>
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Header candidate={candidate} />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar
            onToggleSidebar={handleToggleSidebar}
            candidate={candidate}
          />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            {course ? (
              <>
                <CourseBanner course={course} />
                <CourseChapterList chapters={course.chapters} course={course} />
              </>
            ) : (
              <p>Course details not available</p>
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
