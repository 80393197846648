import React, { useState } from "react";
import "./UserList.css";
import { ReactComponent as MoreIcon } from "../../../assets/images/More.svg";

function UserLists({toggleUserForm}) {

  
  // Sample user data, replace this with your actual user data
  const allUsers = [
    {
      name: "Jethro Jamero",
      email: "jethro@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "Admin",
      status: "Open",
    },
    {
      name: "Arsalan",
      email: "arsalan@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "User",
      status: "Open",
    },
    {
      name: "Tyler",
      email: "tyler@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "Admin",
      status: "Open",
    },
    {
      name: "Jordie",
      email: "jk@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "Admin",
      status: "Open",
    },
    {
      name: "Ben",
      email: "ben@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "User",
      status: "Open",
    },
    {
      name: "Claire",
      email: "claire@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "Admin",
      status: "Open",
    },
    {
      name: "Trish",
      email: "trish@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "User",
      status: "Open",
    },
  ];

  const [visibleUsers, setVisibleUsers] = useState(3); // Show 3 Users initially

  // Show next 3 Users
  const showMoreUsers = () => {
    setVisibleUsers(visibleUsers + 3);
  };


  const getColorForLetter = (letter) => {
    const colors = {
      A: "#FFD757",
      B: "#288C03",
      C: "#03A9F4",
      D: "#FF5722",
      E: "#8E24AA",
      F: "#03DAC5",
      G: "#2196F3",
      H: "#FF4081",
      I: "#9C27B0",
      J: "#4CAF50",
      K: "#FF9800",
      L: "#607D8B",
      M: "#00BCD4",
      N: "#FFEB3B",
      O: "#8BC34A",
      P: "#673AB7",
      Q: "#FF4081",
      R: "#9E9E9E",
      S: "#3F51B5",
      T: "#00BFAE",
      U: "#9E9E9E",
      V: "#4CAF50",
      W: "#3F51B5",
      X: "#E91E63",
      Y: "#FFEB3B",
      Z: "#9C27B0",
    };
    return colors[letter.toUpperCase()] || "rgb(200, 200, 200)";
  };

  return (
    <div className="users_section">
      <div className="users_header">
        <h4>Users</h4>
        <button onClick={toggleUserForm}>Add New Users</button>
      </div>

      <div className="users_lists">
        {allUsers.slice(0, visibleUsers).map((user, index) => (
          <div className="users_list" key={index}>
<div>
<div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: getColorForLetter(
                user.name ? user.name.charAt(0) : "?"
              ),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "8px",
            }}
          >
            <span style={{ fontSize: "18px", color: "#fff" }}>
              {user.name ? user.name.charAt(0) : ""}
            </span>
          </div>
</div>
            <div>
            <div className="userinfo">
      
              
      <h5>
        {user.name}
        <span className="userType">{user.userType}</span>
      </h5>

      <div className="more_icon">
      <MoreIcon/>
      </div>
    </div>

    <div className="users_detail">
      <span className="users_email">{user.email}</span>
      <span className="users_phone">{user.phone}</span>
    </div>
              
            </div>
        
          </div>
        ))}
      </div>

      {/* Show the "More Users" button only if there are more Users to show */}
      {visibleUsers < allUsers.length && (
        <div className="show_more">
          <button onClick={showMoreUsers}>
            +{allUsers.length - visibleUsers} more Users
          </button>
        </div>
      )}
    </div>
  );
}

export default UserLists;
