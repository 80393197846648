import React, { useState, useEffect, useRef } from "react";
import "./CertificationFilter.css";
import { ReactComponent as IconAward } from "../../../assets/images/icon-award-fill.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";

import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";
const CertificationFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectCertification,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [filterType, setFilterType] = useState("contains one of");
  const [isConfirmed, setIsConfirmed] = useState(false); // Toggle between filter and confirmation views
  const modalRef = useRef(null);

  // Extract and clean certifications array
  // const certificationsList = [
  //   ...new Set(
  //     candidateData.flatMap((candidate) =>
  //       (candidate.certifications || []).map((cert) =>
  //         cert ? String(cert).trim() : ""
  //       )
  //     )
  //   ),
  // ].filter((cert) => cert !== ""); // Remove empty strings

  const certificationsList = [
    ...new Set(
      candidateData.flatMap((candidate) => {
        // Ensure certifications is always an array
        const certifications = Array.isArray(candidate.certifications)
          ? candidate.certifications
          : [];
        return certifications.map((cert) => (cert ? String(cert).trim() : ""));
      })
    ),
  ].filter((cert) => cert !== ""); // Remove empty strings
  

  // Filter certifications based on search term
  const filteredCertifications =
    searchTerm && searchTerm.length >= 2
      ? certificationsList.filter((certification) =>
          String(certification).toLowerCase().includes(searchTerm.toLowerCase())
        )
      : certificationsList;

  const handleSelectCertification = (certification) => {
    setSelectedCertifications((prev) =>
      prev.includes(certification)
        ? prev.filter((cert) => cert !== certification)
        : [...prev, certification]
    );
  };

  const handleDone = () => {
    if (selectedCertifications.length > 0) {
      onSelectCertification(selectedCertifications); // Pass selected certifications to parent
      setIsConfirmed(true); // Switch to confirmation view
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false); // Switch back to filter-options view
  };

  const handleDelete = () => {
    setSelectedCertifications([]); // Clear selected certifications
    setIsConfirmed(false); // Switch back to filter-options view
  };

  // Ensure that the modal is not shown if it's closed
  if (!isOpen) return null;

  return (
    <div className="certification-modal-main">
      <div className="certification-modal-content" ref={modalRef}>
        {isConfirmed ? (
          // Confirmation view
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                 <IconAward/>
                  Certifications
                </h6>
              </div>
              <div className="manage-icons">
              <EditIcon/>
               
            <DeleteIcon/>

            
              </div>
            </div>
            <p className="selected-certifications">
              {filterType} {selectedCertifications.join(", ")}
            </p>
          </div>
        ) : (
          // Default filter-options view
          <>
            <div className="filter-options">
              <h6>
             <IconAward/>
                Certification
              </h6>
              <div className="certification-list-radio-buttons">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="contains one of"
                    checked={filterType === "contains one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Contains one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="does not contain any of"
                    checked={filterType === "does not contain any of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Does not contain any of
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Certification"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="certification-search-bar"
            />
            <div className="certification-list">
              {searchTerm.length >= 2 && filteredCertifications.length > 0
                ? filteredCertifications.map((cert, index) => (
                    <div
                      key={index}
                      className={`certification-item ${
                        selectedCertifications.includes(cert) ? "selected" : ""
                      }`}
                      onClick={() => handleSelectCertification(cert)}
                    >
                      {cert}
                    </div>
                  ))
                : searchTerm.length >= 2 && (
                    <p className="no-results">No certifications found</p>
                  )}
            </div>

            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CertificationFilter;
