import React from "react";

import { ReactComponent as HiredCandidateIcon } from "../../../assets/images/hiredCandidates.svg";

import "./AssignedHiredBox.css";
function AssignedHiredBox() {
  return (
    <div className="box_count">
      <div className="box_count_profile">
        <HiredCandidateIcon style={{marginRight:"20px"}}/>
      </div>
      <div className="counting_number">
        <h6>
          15 <span>NEW</span>{" "}
        </h6>

        <p>Candidates Hired</p>
      </div>
    </div>
  );
}

export default AssignedHiredBox;
