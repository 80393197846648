import React, { useEffect, useState } from "react";
import axios from "axios";
import "./NotesList.css";
// import userImg from "../../../assets/images/user_Img.png";

function NotesList({ candidate, admin }) {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/api/admin/candidates/${candidate._id}/notes`
        );

        // Sort the notes by date in descending order
        const sortedNotes = response.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setNotes(sortedNotes);
      } catch (error) {
        console.error("Error fetching notes", error);
      }
    };

    if (candidate && candidate._id) {
      fetchNotes();
    }
  }, [candidate]);

  // Custom function to format the date
  const formatDate = (date) => {
    const options = {
      month: "short", // e.g., "Sep"
      day: "numeric", // e.g., "25"
    };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const time = new Date(date)
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();

    return `${formattedDate} at ${time}`;
  };

  return (
    <div className="note_list_section">
      {notes.length > 0 ? (
        notes.map((note, index) => (
          <div key={index} className="note_box">
            <div className="user_info_date">
              <div className="user_profile_info">
                {/* <img src={userImg} alt="user_icon" /> */}
                <h6>{admin.name}</h6>
              </div>

              <small>{formatDate(note.date)}</small>
            </div>

            <div dangerouslySetInnerHTML={{ __html: note.content }} />
          </div>
        ))
      ) : (
        <p>No notes available.</p>
      )}
    </div>
  );
}

export default NotesList;
