import React, { useState, useEffect, useRef } from "react";
import "./CompanyNameFilter.css";
// import iconUser from "../../../assets/images/icon-user-fill.png";
import { ReactComponent as IconUser } from "../../../assets/images/icon-user-fill.svg";

import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";

import { ReactComponent as DeleteIcon } from "../../../assets/images/trash.svg";
const CompanyNameFilter = ({
  isOpen,
  onClose,
  companyData,
  onSelectCompanyName,
  filterType,
  setFilterType, // Add setFilterType as a prop
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false); // State to toggle views
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {};

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleDone = () => {
    if (searchTerm && searchTerm.length >= 2) {
      onSelectCompanyName(searchTerm); // Pass the search term as the selected manager
      setSelectedCompanyName(searchTerm); // Set the selected manager
      setIsConfirmed(true); // Switch to confirmation view
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false); // Switch back to filter-options view
  };

  const handleDelete = () => {
    setSelectedCompanyName(null); // Clear selected manager
    setSearchTerm(""); // Reset search term
    setIsConfirmed(false); // Switch back to filter-options view
  };

  // Ensure that the modal is not shown if it's closed
  if (!isOpen) return null;

  return (
    <div className="manager-modal-main">
      <div className="manager-modal-content" ref={modalRef}>
        {isConfirmed ? (
          // Confirmation view after clicking Done
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                    <IconUser/>
                  Company Name
                </h6>
              </div>

              <div className="manage-icons">
              <EditIcon/>
            <DeleteIcon/>
              </div>
            </div>

            <p className="selected-manager-name">
              {filterType} {""}
              {selectedCompanyName}
            </p>
          </div>
        ) : (
          // Default filter-options view
          <>
            <div className="filter-options">
              <h6>
                  <IconUser/>
                Company Name
              </h6>
              <div className="manager-list-radio-buttons">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={filterType === "is one of"}
                    onChange={(e) => setFilterType(e.target.value)} // Update filterType via setFilterType
                  />
                  Is one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={filterType === "is not one of"}
                    onChange={(e) => setFilterType(e.target.value)} // Update filterType via setFilterType
                  />
                  Is not one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={filterType === "is set"}
                    onChange={(e) => setFilterType(e.target.value)} // Update filterType via setFilterType
                  />
                  Is set
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={filterType === "is not set"}
                    onChange={(e) => setFilterType(e.target.value)} // Update filterType via setFilterType
                  />
                  Is not set
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Company Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="manager-search-bar"
            />
            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyNameFilter;
