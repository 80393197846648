// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-placeholder-uploadfiles{
    display: flex;
    align-items: center;
}

.file-placeholder-uploadfiles img{
    margin-right: 10px;
    width: 30px;
}

.file-placeholder-uploadfiles p{
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/Companies/Upload Files/UploadFiles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".file-placeholder-uploadfiles{\n    display: flex;\n    align-items: center;\n}\n\n.file-placeholder-uploadfiles img{\n    margin-right: 10px;\n    width: 30px;\n}\n\n.file-placeholder-uploadfiles p{\n    padding-bottom: 0px;\n    margin-bottom: 0px;\n    font-size: 14px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
