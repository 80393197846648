// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box_count{
    background-color: white;
    display: flex
;
    /* align-items: center; */
    padding: 20px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
}

.box_count_profile img{
    width: 45px;
    height: 45px;
    margin-right: 20px;
}

 .counting_number h6{
    font-size: 45px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: bold;
}

 .counting_number h6 span{
    color: #ffc500;
    padding: 0;
    border: none;
    font-size: 12px;
}

.counting_number p{
    font-size: 14px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Candidates/Assigned Companies Box/AssignedCompanyBox.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB;AACJ;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;CAEC;IACG,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;CAEC;IACG,cAAc;IACd,UAAU;IACV,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".box_count{\n    background-color: white;\n    display: flex\n;\n    /* align-items: center; */\n    padding: 20px 20px;\n    border-radius: 10px;\n    cursor: pointer;\n    margin-top: 20px;\n}\n\n.box_count_profile img{\n    width: 45px;\n    height: 45px;\n    margin-right: 20px;\n}\n\n .counting_number h6{\n    font-size: 45px;\n    margin-bottom: 0px;\n    padding-bottom: 0px;\n    font-weight: bold;\n}\n\n .counting_number h6 span{\n    color: #ffc500;\n    padding: 0;\n    border: none;\n    font-size: 12px;\n}\n\n.counting_number p{\n    font-size: 14px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
