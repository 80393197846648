import React, { useState } from "react";
import "./WatchMyIntroVideo.css";
import { ReactComponent as VideoIcon } from "../../../assets/images/videoIcon.svg";  
   

import { ReactComponent as PlusIcon } from "../../../assets/images/plusCircle.svg";     
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";

import UploadVideoIntro from "./Upload Video Intro Popup/UploadVideoIntro";

function WatchMyIntroVideo({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Modal open and close functions
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Check if candidate.profileVideoUrl is valid
  const profileVideoUrl = candidate.videoIntroUrl
    ? candidate.videoIntroUrl
    : null; // Make it null if it's not available

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const profileVideoUrlEmb = `${BASE_URL}/${candidate.videoIntroUrl}`;

  return (
    <div className="watch_my_intro">
      <h4>Watch My Video Intro</h4>

      {profileVideoUrl ? (
        <>
          {!hideEditIcon && (
            <div className="editIcon" onClick={openModal}>
                    <EditIcon/>
            </div>
          )}
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              marginTop: "20px",
              borderRadius: "10px",
              border: "1px solid #cec8c875",
              marginBottom: "20px",
            }}
          >
            <video
              src={profileVideoUrlEmb}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Embedded Content"
              controls
            />
          </div>
        </>
      ) : (
        <div className="empty-state">
          <div className="plusIcon" onClick={openModal}>
          <PlusIcon/>
          </div>
        <VideoIcon/>
          <p>
            Add a quick video intro to introduce yourself to hiring companies. A
            short video can make a big impression.
          </p>
          <button onClick={openModal}>Upload Video Intro</button>
        </div>
      )}

      <UploadVideoIntro
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />
    </div>
  );
}

export default WatchMyIntroVideo;
