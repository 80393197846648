// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_user_section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 2px #e0e2e5;
}

.add_user_section .add_user_header {
    border-bottom: 1px solid #b0b7bf;
}

.add_user_section .add_user_header h3 {
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    margin-bottom: 0px !important;
}

.add_user_body {
    padding: 20px;
    border-bottom: 1px solid #b0b7bf;
}

.add_user_body label {
    display: block;
    padding-bottom: 10px;
    color: #4A4D50;
    font-size: 12px;
    font-weight: 600;
}

.add_user_body input {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b0b7bf;
    margin-bottom: 10px;
}

.add_user_footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}

.add_user_footer button {
    background-color: #ffc500;
    border: none;
    padding: 12px 15px;
    border-radius: 10px;
    font-size: 12px;
}

.add_user_footer button:first-child {
    margin-right: 10px;
    background-color: transparent;
    color: #4A4D50;
    border: none;
    padding: 10px 15px;
}


select {
    width: 100%;
    padding: 12px 10px;
    border-radius: 10px;
    border: 1px solid #b0b7bf;
}`, "",{"version":3,"sources":["webpack://./src/components/Companies/NewUsers/AddNewUserForm.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,+BAA+B;AACnC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB;;;AAGA;IACI,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".add_user_section {\n    background-color: white;\n    border-radius: 10px;\n    box-shadow: 0px 1px 2px #e0e2e5;\n}\n\n.add_user_section .add_user_header {\n    border-bottom: 1px solid #b0b7bf;\n}\n\n.add_user_section .add_user_header h3 {\n    font-size: 16px;\n    font-weight: 600;\n    padding: 20px;\n    margin-bottom: 0px !important;\n}\n\n.add_user_body {\n    padding: 20px;\n    border-bottom: 1px solid #b0b7bf;\n}\n\n.add_user_body label {\n    display: block;\n    padding-bottom: 10px;\n    color: #4A4D50;\n    font-size: 12px;\n    font-weight: 600;\n}\n\n.add_user_body input {\n    width: 100%;\n    padding: 10px;\n    border-radius: 10px;\n    border: 1px solid #b0b7bf;\n    margin-bottom: 10px;\n}\n\n.add_user_footer {\n    padding: 20px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.add_user_footer button {\n    background-color: #ffc500;\n    border: none;\n    padding: 12px 15px;\n    border-radius: 10px;\n    font-size: 12px;\n}\n\n.add_user_footer button:first-child {\n    margin-right: 10px;\n    background-color: transparent;\n    color: #4A4D50;\n    border: none;\n    padding: 10px 15px;\n}\n\n\nselect {\n    width: 100%;\n    padding: 12px 10px;\n    border-radius: 10px;\n    border: 1px solid #b0b7bf;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
